import React from "react";
import LoginCard from "@/components/cards/LoginCard";
import welcom from "@/assets/WelcomeToKalSense.svg";
import styles from "./css/LoginPage.module.css";
import '../translations/i18n';
import { useTranslation } from 'react-i18next';

export const LoginPage: React.FC = () => {
  const { t, i18n } = useTranslation('LoginPage');
  const isRtl = i18n.dir() === 'rtl'
  return (
    <div className={styles.page}>
      <div className={styles.welcome}>
        <img
          src={welcom}
          alt={t('title')}
        ></img>
      </div>
      <div className={styles.text}>
        <p>{t('description')}</p>
      </div>
      <LoginCard />
    </div>
  );
};
