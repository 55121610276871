import secureLocalStorage from "react-secure-storage";
import { PostRequest } from "./requests";
import { Interact } from "@/types";

// WebSocket connection
let socket: WebSocket | null = null;
// socketIO
const ensureSocketConnection = (path: string): Promise<WebSocket> => {
    return new Promise((resolve, reject) => {
      const token: string = JSON.parse(secureLocalStorage.getItem('token') as string);
      const wsUrl = process.env.REACT_APP_API_BASE_URL?.replace('https://', 'wss://');
      if (!wsUrl) {
        reject(new Error('REACT_APP_API_BASE_URL is not defined'));
        return;
      }
  
      // Open a WebSocket connection
      socket = new WebSocket(`${wsUrl}/${path}`);
  
      socket.onopen = () => {
        // Send the token along with the request data as the first message
        const messageData = JSON.stringify({
          token: token
        });
        if (socket) {  // Check if socket is not null
          socket.send(messageData);
          resolve(socket);
        } else {
          reject(new Error('WebSocket is not connected'));
        }
      };
  
      socket.onerror = (error) => reject(error);
    });
  };

  export const interract = async (data: Interact) => {
    // try {
      // Attempt to establish WebSocket connection
    //   const ws = await ensureSocketConnection('ws/chat/interact');
      
    //   return new Promise((resolve, reject) => {
        // ws.send(JSON.stringify(data));
  
        // Handle messages from the server (application-level errors included)
        // ws.onmessage = (event) => {
        //   const responseData = JSON.parse(event.data);
  
          // Check if the response indicates an error (e.g., status code 403, 500, etc.)
        //   if (responseData.status_code && responseData.status_code >= 400) {
            // Reject the promise with the error response
            // reject(responseData);
        //   } else {
            // Otherwise, resolve with the valid data
            // resolve(responseData.data);
        //   }
        // };
  
        // Handle WebSocket-level errors (e.g., connection failure)
        // ws.onerror = (error) => reject(new Error(`WebSocket error: ${error}`));
    //   });
      
    // } catch (wsError: unknown) {  // Specify the type of wsError
    //   console.log('WebSocket error, falling back to HTTP:', (wsError as Error).message);  // Cast to Error
  
      // Only fallback to HTTP if WebSocket connection fails, not for application-level errors
    //   try {
        return await PostRequest("/api/system_service/interact", data, 50000);
    //   } catch (httpError) {
        // console.log('Error in HTTP request:', httpError);
        // throw httpError;
    //   }
    // }
  };
