import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setChatState } from "@/store/chatSlice";
import { setMediaFiles } from "@/store/filesSlice";
import { RootState } from "@/store/store";
import ChatInput from "./ChatInput";
import ChatPrompt from "./ChatPrompt";
import ChatFocus from "./ChatFocus";
import { FileData, UserData } from "@/types";
import styles from "./css/ChatHeader.module.css";
import ChatTag from "./ChatTag";
import { useChatInteracte } from "@/hooks/chat/useChatInteract";
import { useGenerateImage } from "@/hooks/files/useGenerateImage";
import store from "@/store/store";
import { useGetAllFiles } from "@/hooks/files/useGetAllFiles";
import { useGetFile } from "@/hooks/files/useGetFile";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { langDirection } from "@/utils/languages";

interface ChatHeaderProps {
  user: UserData;
}

const productFromFocus = {
  "doc": "KalDocs",
  "audio": "KalAudio",
  "media": "KalMedia",
  "none": ""
}

const ChatHeader: React.FC<ChatHeaderProps> = ({ user }) => {
  const [inputValue, setInputValue] = useState("");
  const [isChatFocusVisible, setIsChatFocusVisible] = useState(false);
  const chat = useSelector((state: RootState) => state.chat);
  const promptTagMode = chat.promptTagMode; // Get promptTagMode from the store
  const orgId = useSelector((state: RootState) => state.org.userOrg.id);
  const orgName = useSelector((state: RootState) => state.org.userOrg.org_name);
  const { projects } = useSelector((state: RootState) => state.projects);
  const { allFiles, mediaFiles } = useSelector(
    (state: RootState) => state.files
  );
  const dispatch = useDispatch();
  const selectedPrompt = useSelector(
    (state: RootState) => state.chat.selectedPrompt
  );
  const [projectId, setProjectId] = useState<string>(
    projects.length > 0 ? projects[0].id : "null"
  );
  const getFile = useGetFile();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation("ChatInteractMessages");

  useEffect(() => {
    if (inputValue === "") {
      dispatch(setChatState({ toShowPrompt: true }));
    } else {
      dispatch(setChatState({ toShowPrompt: false }));
    }
  }, [inputValue]);

  useEffect(() => {
    if (selectedPrompt) {
      handlePromptClick(selectedPrompt.id, selectedPrompt.text);
    }
  }, [selectedPrompt, dispatch]);

  useEffect(() => {
    if (location.pathname !== `/${orgName}/search-res`) {
      dispatch(setChatState({ promptTagMode: false }));
      setInputValue("");
    }
  }, [location, orgName, dispatch]);

  const handlePromptExit = () => {
    console.log("handle close");
    dispatch(setChatState({ promptTagMode: false }));
    setInputValue("");
  };

  const getAllFiles = useGetAllFiles({
    onSuccess(data) {
      if (mediaFiles.length > 0) {
        const mediaFilesNotFetchedOrUpdated = data.filter((file: FileData) => {
          if (file.product !== "KalMedia") return false; // Only interested in KalMedia

          const existingFile = mediaFiles.find((f) => f.id === file.id);

          // File is not in mediaFiles or its task_done status differs
          return (
            !existingFile ||
            existingFile.status.task_done !== file.status.task_done
          );
        });

        if (mediaFilesNotFetchedOrUpdated.length > 0) {
          const fetchPromises = mediaFilesNotFetchedOrUpdated.map(
            (file: FileData) =>
              getFile.mutateAsync({
                file_id: file.id,
                org_id: file.org_id,
                project_id: file.project_id,
              })
          );

          // Wait for all the fetches to complete
          Promise.all(fetchPromises)
            .then((fetchedFiles) => {
              // Combine the fetched files with any existing media files
              const newFiles = [...fetchedFiles];
              console.log(newFiles);
              dispatch(setMediaFiles(newFiles)); // Dispatch the updated media files
            })
            .catch((error) => {
              console.error("Error fetching files:", error);
            });
        }
      }
    },
  });
  const chatInterract = useChatInteracte({
    onSuccess: (data) => {
      const filteredFiles = allFiles.filter((file) => {
        const matchesId = data.file_ids.includes(file.id);
        const matchesFocus =
          chat.chatSearchFocus === "doc"
            ? file.product === "KalDocs"
            : chat.chatSearchFocus === "audio"
              ? file.product === "KalAudio"
              : chat.chatSearchFocus === "media"
                ? file.product === "KalMedia"
                : true;
        return matchesId && matchesFocus;
      });
      if (chat.isChatResMode) {
        dispatch(
          setChatState({
            responseMessage: filteredFiles.length > 0 ? data.answer : t('NotFoundDocuments') ,
            responseFilteredFiles: filteredFiles,
            toShowPrompt: false,
            isChatResMode: true,
            chatWaiting: false,
          })
        );
      }
    },
    onError: (error) => {
      console.log("error", error);
      if (chat.isChatResMode) {
        dispatch(
          setChatState({
            responseMessage: t('ServerError'),
            responseFilteredFiles: [],
            toShowPrompt: false,
            isChatResMode: true,
            chatWaiting: false,
          })
        );
      }
    },
  });

  const generateFile = useGenerateImage({
    onSuccess(data) {
      console.log(data);
      if (chat.isChatResMode) {
        dispatch(
          setChatState({
            responseFileId: data.file_id,
            bodyFile: {
              org_id: orgId,
              project_id: projectId,
              file_id: data.file_id,
            },
            toShowPrompt: false,
            isChatResMode: true,
            chatWaiting: true,
          })
        );
      }
      getAllFiles.mutate({ org_id: orgId, project_id: projectId });
    },
    onError(error) {
      if (chat.isChatResMode) {
        dispatch(
          setChatState({
            responseMessage:
              t('ServerError'),
            responseFilteredFiles: [],
            toShowPrompt: false,
            isChatResMode: true,
            chatWaiting: false,
          })
        );
      }
    },
  });

  const [prompts, setPrompts] = useState<{ id: string; textH: string; textE: string; product: string; }[]>([
    // {
    //   id: "4",
    //   textH: 'מסמכים בהם המילה "צוקרברג" מופיעה יותר מפעמיים',
    //   textE:
    //     'Documents in which the word "Zuckerberg" appears more than twice.',
    //   product: "doc",
    // },
    // {
    //   id: "5",
    //   textH: 'מסמכים בהם מופיעה המשפט "האיום האיראני"',
    //   textE: 'Documents in which the sentence "the Iranian threat" appears.',
    //   product: "doc",
    // },
    // {
    //   id: "6",
    //   textH: 'מסמכים בהם המילה "אויב" מופיעה יותר מפעמיים',
    //   textE: 'Documents in which the word "enemy" appears more than twice.',
    //   product: "doc",
    // },
    // {
    //   id: "7",
    //   textH: 'מסמכים שכוללים את המילה "בינה מלאכותית"',
    //   textE: 'Documents that include the word "artificial intelligence"',
    //   product: "doc",
    // },
  ]);

  const handleCloseClick = () => {
    setIsChatFocusVisible(false);
  };

  const handlePromptClick = (promptId: string, promptText: string) => {
    dispatch(setChatState({ promptTagMode: true }));
    setInputValue(promptText);
    // Move the onSubmit call to the next render cycle
    setTimeout(() => {
      handleSubmit(promptText);
    }, 0);
  };

  const onSubmit = async (message: string) => {
    let local_projectId: string = projectId;
    dispatch(
      setChatState({
        toShowPrompt: false,
        isChatResMode: true,
        chatWaiting: true,
        chatQuery: message,
        responseMessage: "",
        responseFilteredFiles: [],
        responseFileId: "",
        bodyFile: null,
      })
    );

    await new Promise<void>((resolve) => {
      const checkFetched = () => {
        const state = store.getState();
        return state.files.filesFetched && state.projects.projectsFetched;
      };

      if (checkFetched()) {
        const state = store.getState();
        if (local_projectId === "null") {
          local_projectId =
            state.projects.projects.length > 0
              ? state.projects.projects[0].id
              : "null";
          setProjectId(
            state.projects.projects.length > 0
              ? state.projects.projects[0].id
              : "null"
          );
        }
        resolve();
      } else {
        const unsubscribe = store.subscribe(() => {
          if (checkFetched()) {
            const state = store.getState();
            if (local_projectId === "null") {
              local_projectId =
                state.projects.projects.length > 0
                  ? state.projects.projects[0].id
                  : "null";
              setProjectId(
                state.projects.projects.length > 0
                  ? state.projects.projects[0].id
                  : "null"
              );
            }
            unsubscribe();
            resolve();
          }
        });
      }
    });

    try {
      if (chat.chatSearchFocus === "generate") {
        await generateFile.mutateAsync({
          org_id: orgId,
          project_id: local_projectId,
          prompt: message,
        });
      } else {
        await chatInterract.mutateAsync({
          org_id: orgId,
          project_id: local_projectId,
          query: message,
          product_name: productFromFocus[chat.chatSearchFocus]
        });
      }
    } catch (error) {
      console.error("Error submitting message:", error);
    } finally {
    }
  };

  const handleSubmit = (message: string) => {
    onSubmit(message);
    navigate(`${orgName}/search-res`);
  };

  return (
    <>
      <div className={styles.headerFocus}>
        {isChatFocusVisible && <ChatFocus onClose={handleCloseClick} />}
      </div>
      <div className={styles.headerContainer}>
        {chat.toShowPrompt &&
          prompts.length > 0 &&
          chat.chatSearchFocus !== "none" && (
            <div className={styles.promptsContainer}>
              {prompts
                .filter((prompt) => prompt.product === chat.chatSearchFocus)
                .map((prompt) => (
                  <ChatPrompt
                    key={prompt.id}
                    prompt={prompt}
                    onPromptClick={handlePromptClick}
                  />
                ))}
            </div>
          )}
        <div className={styles.chatInputWrapper}>
          {promptTagMode ? (
            <ChatTag
              onClose={() => {
                handlePromptExit();
              }}
              tagPrompt={inputValue}
            />
          ) : (
            <ChatInput
              userName={user.first_name}
              inputValue={inputValue}
              setInputValue={setInputValue}
              onSubmit={handleSubmit}
              onFocus={(value) => {
                setIsChatFocusVisible(value);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ChatHeader;
