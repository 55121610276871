import { AiOutlineSearch } from "react-icons/ai";
import { RiArrowUpSFill, RiArrowDownSFill } from "react-icons/ri";
import { FaFilter } from "react-icons/fa";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  ColumnDef,
  flexRender,
  SortingState,
} from "@tanstack/react-table";
import { FileData, ProjectColors } from "@/types";
import styles from "./css/FileTable.module.css";
import { ProjectTag } from "../tags/ProjectTag";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Button } from "../Buttons";
import { InputField } from "../inputFields/inputFields";
import TruncateText from "../addons/TruncatedText";
import { useTranslation } from 'react-i18next';

interface FilesTableProps {
  className?: string;
  data: FileData[];
}

const SortIcons: React.FC<{ isSorted: boolean | string }> = ({ isSorted }) => {
  return (
    <div className="flex flex-col ml-2 leading-none">
      <RiArrowUpSFill
        className={`${isSorted === "asc" ? "text-black" : "text-gray-400"} text-xl -mb-2`}
      />
      <RiArrowDownSFill
        className={`${isSorted === "desc" ? "text-black" : "text-gray-400"} text-xl -mt-1`}
      />
    </div>
  );
};

export const FilesTable: React.FC<FilesTableProps> = ({ className, data }) => {
  const { t } = useTranslation('FilesTable');

  const allProjects = useSelector(
    (state: RootState) => state.projects.projects
  );
  const { orgName, type } = useParams<{ orgName: string, type: string }>();
  const navigate = useNavigate();
  const colors = ["green", "pink", "yellow", "blue"];
  const randomColor = () => colors[Math.floor(Math.random() * colors.length)];
  const [activeFilter, setActiveFilter] = useState<string | null>(null);
  const filterInputRef = useRef<HTMLInputElement>(null);
  const [inputFilter, setInputFilter] = useState("");
  const [filteredFiles, setFilteredFiles] = useState<FileData[]>(data);
  const toggleFilter = (columnId: string) => {
    setActiveFilter((prev) => (prev === columnId ? null : columnId));
    setInputFilter("");
  };
  const location = useLocation();
  const decodedPathname = decodeURIComponent(location.pathname);
  const handleFilter = (columnId: string) => {
    switch (columnId) {
      case "name":
        setFilteredFiles(
          data.filter((file) => file.name.toLowerCase().includes(inputFilter))
        );
        break;
      case "source_name":
        setFilteredFiles(
          data.filter((file) =>
            file.source_name.toLowerCase().includes(inputFilter)
          )
        );
        break;
      case "created_at":
        setFilteredFiles(
          data.filter((file) =>
            file.created_at.toLowerCase().includes(inputFilter)
          )
        );
        break;
      case "duration":
        setFilteredFiles(
          data.filter((file) =>
            (file.duration?.toLowerCase() ?? "").includes(inputFilter)
          )
        );
        break;
      case "project_id":
        setFilteredFiles(
            data.filter((file) => allProjects.find((project) => project.id === file.project_id)?.name.toLowerCase().includes(inputFilter))
        )
        break;
    }
  };

  useEffect(() => {
    if (activeFilter && filterInputRef.current) {
      filterInputRef.current.focus();
    }
  }, [activeFilter]);

  const columns = useMemo<ColumnDef<FileData>[]>(
    () => {
      const baseColumns: ColumnDef<FileData>[] = [
        {
          accessorKey: "name",
          header: () => <div className={styles.headerText}>{t('FileName')}</div>,
          cell: ({ row }) => {
            return (
              <a
                onClick={() => {
                  row.original.status.task_done ? navigate(`/${orgName}/${type}/file/${row.original.id}`): ''
                }}
              >
                <TruncateText text={row.original.name} maxLength={22}/>
              </a>
            );
          },
        },
        {
          accessorKey: "project_id",
          header: () => <div className={styles.headerText}>{t('ProjectName')}</div>,
          cell: ({ row }) => {
            const project = allProjects.find(
              (project) => project.id === row.original.project_id
            );
            return (
              <ProjectTag
                name={project?.name || "תמלול ועדה רפואית"}
                color={project?.color || (randomColor() as ProjectColors)}
                size="lg"
              />
            );
          },
          sortingFn: (rowA, rowB) => {
            const projectA =
              allProjects.find((p) => p.id === rowA.original.project_id)?.name ||
              "";
            const projectB =
              allProjects.find((p) => p.id === rowB.original.project_id)?.name ||
              "";
            return projectA.localeCompare(projectB);
          },
        },
        {
          accessorKey: "source_name",
          header: () => <div className={styles.headerText}>{t('Source')}</div>,
        },
        {
          accessorKey: "created_at",
          header: () => <div className={styles.headerText}>{t('CreatedAt')}</div>,
          sortingFn: (rowA, rowB) => {
            const parseDate = (dateStr: string) => {
              const [day, month, year] = dateStr.split("/").map(Number);
              return new Date(2000 + year, month - 1, day);
            };
  
            const dateA = parseDate(rowA.original.created_at);
            const dateB = parseDate(rowB.original.created_at);
            return dateA.getTime() - dateB.getTime();
          },
        },
      ];
  
      if (decodedPathname.includes("/audio/")) {
        baseColumns.push({
          accessorKey: "duration",
          header: () => <div className={styles.headerText}>{t('CallDuration')}</div>,
          sortingFn: (rowA, rowB) => {
            const timeToSeconds = (time: string) => {
              const [hours, minutes, seconds] = time.split(":").map(Number);
              return hours * 3600 + minutes * 60 + seconds;
            };
            return (
              timeToSeconds(rowA.original.duration as string) -
              timeToSeconds(rowB.original.duration as string)
            );
          },
        });
      }
  
      return baseColumns;
    },
    [allProjects, orgName, navigate, randomColor, decodedPathname]
  );
  

  const [sorting, setSorting] = useState<SortingState>([]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, columnId: string) => {
    if (event.key === "Enter") {
      handleFilter(columnId);
    }
  };

  const table = useReactTable({
    data: filteredFiles,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (filteredFiles.length === 0) {
    return (
      <div className={`${styles.tableContainer} ${className}`}>
        <table className={styles.emptyTable}>
          <thead className={styles.tableHeadersRow}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={`${styles.headerText} ${styles.tableHeader}`}
                  >
                    <div className="flex items-center justify-between">
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                      <div className="flex items-center">
                        <div
                          className="cursor-pointer"
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          <SortIcons isSorted={header.column.getIsSorted()} />
                        </div>
                        <FaFilter
                          className="text-gray-400 ml-2 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleFilter(header.column.id);
                          }}
                        />
                      </div>
                    </div>
                    {activeFilter === header.column.id && (
                      <div className={styles.filterInputContainer}>
                        <div className={styles.inputFilterWrapper}>
                          <InputField
                            showError={false}
                            placeholder={t("SearchName")}
                            inputType="text"
                            data={inputFilter}
                            setData={setInputFilter}
                            id=""
                            className={styles.inputFilter}
                            onKeyDown={(e) => handleKeyDown(e, header.column.id)}
                          />
                        </div>
                        <div className={styles.filterButtons}>
                          <Button
                            className={styles.searchFilter}
                            icon="left-side"
                            iconComponent={AiOutlineSearch}
                            text={t("Search")}
                            onClick={() => {
                              handleFilter(header.column.id);
                            }}
                            id={"filter-table-btn"}
                          />
                          <button
                            className={styles.resetFilter}
                            onClick={() => {
                              setInputFilter("");
                              setFilteredFiles(data);
                            }}
                          >
                            {t('Reset')}
                          </button>
                        </div>
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            <tr>
              {columns.map((column, index) => (
                <td key={index} className={styles.emptyCell}></td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className={`${styles.tableContainer} ${className}`}>
      <table className={styles.table}>
        <thead className={styles.tableHeadersRow}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={`${styles.headerText} ${styles.tableHeader}`}
                >
                  <div className="flex items-center justify-between">
                    <div>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </div>
                    <div className="flex items-center">
                      <div
                        className="cursor-pointer"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <SortIcons isSorted={header.column.getIsSorted()} />
                      </div>
                      <FaFilter
                        className="text-gray-400 ml-2 cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleFilter(header.column.id);
                        }}
                      />
                    </div>
                  </div>
                  {activeFilter === header.column.id && (
                    <div className={styles.filterInputContainer}>
                      <div className={styles.inputFilterWrapper}>
                        <InputField
                          showError={false}
                          placeholder="Search Name"
                          inputType="text"
                          data={inputFilter}
                          setData={setInputFilter}
                          id=""
                          className={styles.inputFilter}
                          onKeyDown={(e) => handleKeyDown(e, header.column.id)}
                        />
                      </div>
                      <div className={styles.filterButtons}>
                        <Button
                          className={styles.searchFilter}
                          icon="left-side"
                          iconComponent={AiOutlineSearch}
                          text="Search"
                          onClick={() => {
                            handleFilter(header.column.id);
                          }}
                          id={"filter-table-btn"}
                        />
                        <button
                          className={styles.resetFilter}
                          onClick={() => {
                            setInputFilter("");
                            setFilteredFiles(data);
                          }}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className={styles.bodyRow}>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className={styles.tableRow}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className={`${styles.rowText} ${cell.column.id === "name" ? styles.fileName : ""}`}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FilesTable;
