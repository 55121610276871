import React, { useState } from "react";
import styles from "./css/MenuButton.module.css";
import { useTranslation } from "react-i18next";
import { langDirection } from "@/utils/languages";

interface MenuButtonProps {
  iconComponent?: React.ElementType;
  iconSrc?: string;
  iconColorType: "fill" | "stroke" | "text";
  className?: string;
  text: string;
  selected: string;
  onClick: (selected: string) => void;
  showText?: boolean;
  isRtl?: boolean;
  id: string;
}

export const MenuButton: React.FC<MenuButtonProps> = ({
  iconComponent: IconComponent,
  iconSrc,
  className,
  text,
  selected,
  iconColorType,
  id,
  showText = true,
  onClick,
}) => {
  const [hover, setHover] = useState(false);
  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const { t } = useTranslation("SideBar");
  const { i18n } = useTranslation("LanguageSwitcher");
  const currentLanguage = i18n.language;
  const direction = langDirection(currentLanguage);

  return (
    <div
      className={`${styles.button} ${styles[direction]} ${className} ${selected === text ? styles.selected : ""} ${!showText ? styles.shrink : ""} group`} // Added isRtl class
      onClick={() => onClick(text)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-testid={id}
    >
      {direction === "ltr" ? (
        <>
          <div className={styles.iconContainer}>
            {IconComponent ? (
              <IconComponent
                dir={direction}
                className={`${styles.icon} ${selected === text ? styles.selected : ""} ${hover ? styles.hover : ""} ${iconColorType === "fill" ? styles.fillColor : iconColorType === "stroke" ? styles.strokeColor : styles.textColor}`}
              />
            ) : (
              <img
                src={iconSrc}
                alt=""
                className={`${styles.icon} ${selected === text ? styles.selected : ""} ${iconColorType === "fill" ? styles.fillColor : iconColorType === "stroke" ? styles.strokeColor : styles.textColor}`}
              />
            )}
          </div>

          {showText && (
            <div
              className={`${styles.text} ${selected === text ? styles.selected : ""} ${hover ? styles.hover : ""}`}
            >
              {t(text)}
            </div>
          )}
        </>
      ) : (
        <>
          {showText && (
            <div
              className={`${styles.text} ${selected === text ? styles.selected : ""} ${hover ? styles.hover : ""}`}
            >
              {t(text)}
            </div>
          )}
          <div className={styles.iconContainer}>
            {IconComponent ? (
              <IconComponent
                className={`${styles.icon} ${styles.flipIcon} ${selected === text ? styles.selected : ""} ${hover ? styles.hover : ""} ${iconColorType === "fill" ? styles.fillColor : iconColorType === "stroke" ? styles.strokeColor : styles.textColor}`}
              />
            ) : (
              <img
                src={iconSrc}
                alt=""
                className={`${styles.icon} ${selected === text ? styles.selected : ""} ${iconColorType === "fill" ? styles.fillColor : iconColorType === "stroke" ? styles.strokeColor : styles.textColor}`}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
