import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FileData, ProjectColors } from "@/types";
import styles from "./css/FileCard.module.css";
import { InProcessingIcon } from "@/icons/InProcessingIcon";
import { AudioIcon } from "@/icons/docs/audio";
import { WordIcon } from "@/icons/docs/word";
import { InvoiceIcon } from "@/icons/docs/invoice";
import { DocsIcon } from "@/icons/docs/docs";
import { AudioMegaphoneIcon } from "@/icons/docs/audioMegaphone";
import ImageGeneratingAnimation from "../pageParts/ImageGeneratingAnimation";
import { FolderIcon } from "@/icons/docs/folder";
import { AlbumIcon } from "@/icons/docs/album";
import { ProjectTag } from "../tags/ProjectTag";
import TruncateText from "../addons/TruncatedText";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { RootState } from "../../store/store"
import { langDirection } from "@/utils/languages";



interface FileCardProps {
  className?: string;
  data?: FileData;
  folder?: boolean;
  album?: boolean;
  projectName: string;
  projectColor: ProjectColors;
  disabled?: boolean;
}

export const FileCard: React.FC<FileCardProps> = ({
  className,
  data,
  folder = false,
  album = false,
  projectName,
  projectColor,
  disabled = false
}) => {
  const { t } = useTranslation('FileCard');
  const { i18n } = useTranslation("LanguageSwitcher");
  const currentLanguage = i18n.language;
  const direction = langDirection(currentLanguage);
  const navigate = useNavigate();
  const {orgName} = useParams<{orgName: string}>();
  const mediaFiles = useSelector((state: RootState) => state.files.mediaFiles);

  const fileType = data
    ? !data?.status?.task_done
      ? "aiContainer"
      : data.product === "KalAudio"
        ? "audioContainter"
        : data.product === "KalDocs"
          ? "docsContainter"
          : data.product === "KalMedia"
            ? "albumContainter"
            : "albumContainter"
    : folder
      ? "folderContainter"
      : "albumContainter";
  const filePathType = data
    ? data.product === "KalAudio"
      ? "audio"
      : data.product === "KalDocs"
        ? "doc"
        : data.product === "KalMedia"
          ? "media"
          : ""
    : ""

  // Find the thumbnail URL from mediaFiles
  const thumbnailUrl = data?.product === "KalMedia" 
    ? mediaFiles.find(file => file.id === data.id)?.thumbnail 
    : null;

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [imageSource, setImageSource] = useState<string | null>(null);

  useEffect(() => {
    if (thumbnailUrl) {
      const img = new Image();
      img.src = thumbnailUrl;
      img.onload = () => {
        setIsLoading(false);
        setImageSource(thumbnailUrl);
      };
      img.onerror = () => {
        setIsLoading(false);
        setHasError(true);
      };
    } else {
      setIsLoading(false);
    }
  }, [thumbnailUrl]);

  const handleClick = () => {
    if (data && data?.status?.task_done) {
      navigate(`/${orgName}/${filePathType}/file/${data.id}`);
    }
  };

  return (
    <div className={`${styles.fileCard} ${className}`} onClick={handleClick}>
      <div className={`${styles.fileCardTopContainer} ${!data?.status?.task_done ? styles.notClickable : ''} ${styles[fileType]}`}>
        {data && !data?.status?.task_done ? (
          <>
            <InProcessingIcon className={`${styles.processing} ${styles.iconProcessing} ${direction === "rtl"? styles.rtl: ''}`} />
            <div className={`${styles.typeTextProcessing} ${styles.processing} ${direction === "rtl"? styles.rtl: ''}`}>{t('processing')}</div>
          </>
        ) : (
          data && (
            data.product === "KalDocs" ? (
              <>
                <DocsIcon className={`${styles.docs} ${styles.icon} ${direction === "rtl"? styles.rtl: ''}`} />
              </>
            ) : data.product === "KalAudio" ? (
              <>
                <AudioIcon className={`${styles.audio} ${styles.icon} ${direction === "rtl"? styles.rtl: ''}`} />
              </>
            ) : data.product === "KalMedia" ? (
              <>
                {isLoading || !imageSource ? (
                  <ImageGeneratingAnimation size="xs" />
                ) : hasError ? (
                  <div>Error loading image</div>
                ) : (
                  <img src={imageSource} alt="Thumbnail" className={styles.thumbnail} />
                )}
              </>
            ) : (
              <></>
            )
          )
        )}
        {!data &&
          (folder ? (
            <>
              <FolderIcon className={`${styles.folder} ${styles.icon} ${direction === "rtl"? styles.rtl: ''}`} />
              <div className={`${styles.typeText} ${styles.folder}`}>{t('folder')}</div>
            </>
          ) : album ? (
            <>
              <AlbumIcon className={`${styles.album} ${styles.icon} ${direction === "rtl"? styles.rtl: ''}`} />
              <div className={`${styles.typeText} ${styles.album}`}>{t('album')}</div>
            </>
          ) : (
            <></>
          ))}
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.name}>
            <TruncateText text={data?.name || '...'} maxLength={13} tooltipPlacement="top"/>
        </div>
        <ProjectTag color={projectColor} name={projectName || data?.name} disabled={disabled}/>
      </div>
    </div>
  );
};
