import React, { useState, useRef, useEffect } from "react";
import { EyeInvisible } from "@/icons/eyeinvisible";
import { Eye } from "@/icons/eye";
import styles from "./css/inputFields.module.css";
import { useTranslation } from "react-i18next";
import { langDirection } from "@/utils/languages";

interface InputFieldProps {
  inputType: "password" | "text";
  disabled?: boolean;
  placeholder?: string;
  data: string;
  setData: (value: string) => void;
  id: string;
  className?: string;
  errorMessage?: string;
  showError?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const InputField: React.FC<InputFieldProps> = ({
  inputType,
  disabled = false,
  placeholder = "",
  data,
  setData,
  id,
  className = "",
  errorMessage = "",
  showError = true,
  onKeyDown,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { i18n } = useTranslation("LanguageSwitcher");
  const currentLanguage = i18n.language;
  const direction = langDirection(currentLanguage);

  const determinedInputType =
    inputType === "text" ? "text" : isVisible ? "text" : "password";

  const iconColor = isFocused ? "#1890ff" : "#acabba";

  const handleFocus = () => {
    if (!disabled) {
      setIsFocused(true);
    }
  };

  const handleBlur = () => {
    if (!disabled) {
      setIsFocused(false);
    }
  };

  const toggleVisibility = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (!disabled) {
      event.preventDefault();
      setIsVisible(!isVisible);
    }
  };

  const handleChange = () => {
    if (inputRef.current) {
      setData(inputRef.current.value);
    }
  };

  useEffect(() => {
    if(inputRef.current)
      inputRef.current.value = data;
  }, [data])

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.fieldContainer}`}>
        <div
          className={`${styles.fieldInnerContainer} ${
            inputType === "text" ? styles.fieldInnerContainerText : ""
          } ${disabled ? styles.fieldInputContainerDisabled : ""}`}
        >
          <div
            className={`${styles.fieldInputContainer} ${className} ${
              errorMessage ? styles.fieldInputContainerError : ""
            } ${
              disabled
                ? styles.fieldInputContainerDisabled
                : isFocused
                  ? styles.fieldInputContainerActive
                  : styles.fieldInputContainerDefault
            }`}
          >
            <input
              id={id}
              ref={inputRef}
              className={`${styles.fieldInput} ${
                disabled ? styles.fieldInputDisabled : styles.fieldInputDefault
              } ${styles[direction]}`}
              placeholder={placeholder}
              type={determinedInputType}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onKeyDown={onKeyDown}
              disabled={disabled}
            />

            {inputType === "password" && (
              <div
                onMouseDown={toggleVisibility}
                className={`${styles.icon} ${styles[direction]}`}
                style={{ cursor: disabled ? "default" : "pointer" }}
              >
                {isVisible ? (
                  <Eye color={iconColor} />
                ) : (
                  <EyeInvisible color={iconColor} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {showError && (
        <div className={styles.errorMessageContainer}>
          <div
            className={`${styles.errorMessage} ${errorMessage ? styles.visible : ""}`}
          >
            {errorMessage}
          </div>
        </div>
      )}
    </div>
  );
};
