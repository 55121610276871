import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import store from '../store/store';
import { setToken } from '@/store/userSlice';


export const isTokenExpired = (token: string): boolean => {
  const expirationTimeInSecondsSinceEpoch = JSON.parse(atob(token.split('.')[1])).exp;
  const currentTimeInSecondsSinceEpoch = Math.floor(Date.now() / 1000);
  return (currentTimeInSecondsSinceEpoch >= expirationTimeInSecondsSinceEpoch);
};

export const refreshAccessToken = async (refreshToken: string): Promise<any> => {
  const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;
  const refreshTokenUrl = `https://securetoken.googleapis.com/v1/token?key=${apiKey}`;
  const response = await axios.post(refreshTokenUrl, {
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
  });
  return response.data;
};

const sharedAxiosConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
  },
};

async function requestInterceptor(request: InternalAxiosRequestConfig) {

  if (request.url?.includes('verifyPassword') || request.url?.includes('login')) {
    return request;
  }

  const state = store.getState();
  let token = state.user.token;
  const refreshToken = state.user.refreshToken;

  if (token && isTokenExpired(token)) {
    try {
      const refreshTokenResponse = await refreshAccessToken(refreshToken as string);
      token = refreshTokenResponse.id_token as string;
      store.dispatch(setToken({token, refreshToken: refreshTokenResponse.refresh_token}));
    } catch (error) {
      throw new axios.Cancel('token_expired');
    }
  }
  request.headers['Authorization'] = `Bearer ${token}`;
  return request;
}

function responseInterceptor(response: AxiosResponse) {
  const { data: responseData, status } = response;

  if (responseData.error) {
    return Promise.reject(new Error('response is not valid'));
  }

  if (responseData.success === false) {
    return Promise.reject(new Error('response is not valid'));
  }

  if (status === 403 && responseData.includes('Token expired')) {
    console.error('token_expired');
    return Promise.reject(new Error('token_expired'));
  }

  return response;
}

export const axiosClient = axios.create(sharedAxiosConfig);
axiosClient.interceptors.request.use(requestInterceptor);
axiosClient.interceptors.response.use(responseInterceptor);