// src/store/userSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import secureLocalStorage from 'react-secure-storage';
import { getStringValue, getBooleanValue, getStoredData } from '@/utils/store';
import { UserData } from '@/types';

interface UserState {
  user: UserData;
  userAuth: boolean;
  token: string | null;
  refreshToken: string | null;
  users: UserData[];
}

const emptyUser: UserData = {
  id: "",
  org_id: null,
  role_id: "",
  firebase_uid: "",
  first_name: "",
  last_name: "",
  user_name: "",
  status: "",
  creation_date: "",
  created_by: "",
  user_image: "",
  email: "",
  user_type: "",
  color: "blue",
  refresh_token: ""
};

const initialState: UserState = {
  userAuth: getBooleanValue('userAuth'),
  token: getStringValue('token'),
  refreshToken: getStringValue('refreshToken'),
  user: getStoredData<UserData>('user', emptyUser),
  users: getStoredData<UserData[]>('users', []),
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      state.userAuth = action.payload.userAuth;
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken
      state.user = action.payload.user;

      secureLocalStorage.setItem('userAuth', JSON.stringify(action.payload.userAuth));
      secureLocalStorage.setItem('token', JSON.stringify(action.payload.token));
      secureLocalStorage.setItem('refreshToken', JSON.stringify(action.payload.refreshToken));
      secureLocalStorage.setItem('user', JSON.stringify(action.payload.user));
    },
    setUserObject: (state, action: PayloadAction<UserData>) => {
      state.user = action.payload;

      secureLocalStorage.setItem('user', JSON.stringify(action.payload));
    },
    setAuth: (state, action: PayloadAction<boolean>) => {
      state.userAuth = action.payload;

      secureLocalStorage.setItem('userAuth', JSON.stringify(action.payload));
    },
    setUsers: (state, action: PayloadAction<UserData[]>) => {
      state.users = action.payload;

      secureLocalStorage.setItem('users', JSON.stringify(action.payload));
    },
    setToken: (state, action) => {
      state.token = action.payload.token
      state.refreshToken = action.payload.refreshToken

      secureLocalStorage.setItem('token', JSON.stringify(action.payload.token));
      secureLocalStorage.setItem('refreshToken', JSON.stringify(action.payload.refreshToken));
    },
    clearUser: (state) => {
      state.userAuth = false;
      state.token = null;
      state.user = emptyUser;
      state.users = [];
      state.refreshToken = null;

      secureLocalStorage.removeItem('user');
      secureLocalStorage.setItem('userAuth', 'false');
      secureLocalStorage.removeItem('token');
      secureLocalStorage.removeItem('refreshToken');
      secureLocalStorage.removeItem('users');
    },
  },
});

export const { setUser, setUsers, setAuth, setUserObject, setToken, clearUser } = userSlice.actions;
export default userSlice.reducer;
