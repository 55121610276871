import { useMutation } from '@tanstack/react-query'
import { getAllMediaFiles } from '../../api/files'
import { PostParam } from '@/types'

export function useGetAllMediaFiles(props?: PostParam) {
    const { onSuccess, onError } = props || {};

    return useMutation({
        mutationFn: getAllMediaFiles,
        onSuccess,
        onError
    })
}