import React, { useEffect } from "react";
import { RootState } from "@/store/store";
import { useDispatch, useSelector, } from "react-redux";
import PromptCard from "../components/cards/PromptCard";
import styles from "./css/UserProjectPage.module.css";
import { setChatState, setSelectedPrompt } from "@/store/chatSlice";
import { useTranslation } from 'react-i18next';




interface PagesProps {}

export const UserProjectsPage: React.FC<PagesProps> = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const chatState = useSelector((state: RootState) => state.chat);
  const { t } = useTranslation('UserProjectPage');

  useEffect(() => {
    dispatch(setChatState({isChatResMode: false}))
  }, [chatState.chatSearchFocus])


  const handlePromptClick = (promptId: string, promptText: string) => {
    dispatch(setSelectedPrompt({ id: promptId, text: promptText }));

  };


  return (
    <div className={styles.container}>
      <div className={styles.mainContent}>
        <div className={styles.welcomeMessage}> {t("welcomeMessage")} {user.first_name}</div>
        <div className={styles.continueMessage}>
          {t("continueMessage")}
        </div>
        <div className={styles.promptCardContainer}>
          <PromptCard onClick={handlePromptClick}/>
        </div>
      </div>
    </div>
  );
};
