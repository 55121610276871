import React, { useEffect, useState } from 'react';
import { XIcon } from "@/icons/X";
import { PencillIcon } from "@/icons/PencillIcon";

import styles from './css/ChatTag.module.css';

const ChatTag = ({ onClose, tagPrompt }: { onClose: () => void, tagPrompt: string }) => {
  const [prompt, setPrompt] = useState(tagPrompt);

  const handleClose = () => {
    setPrompt(''); // Clear the prompt
    onClose(); // Call the onClose function
  };

  useEffect(()=>{
    setPrompt(tagPrompt);
  },[tagPrompt])

  const truncatePrompt = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

  return (
    <div className={styles.container}>
    <button><PencillIcon className={styles.icon}/></button>
      <div className={styles.iconWrapper} />
      <div className={styles.contentWrapper}>
        <div className={`${styles.textWrapper} ${styles.singleLine}`}>
          <div className={styles.promptContainer}>
            <span className={styles.text} title={prompt}>
              {truncatePrompt(prompt, 67)}
            </span>
            <button onClick={handleClose} className={styles.closeButton}>
              <XIcon className={styles.closeIcon} />
            </button>
          </div>
        </div>
      </div>
      <div className={styles.iconWrapper} />
    </div>
  );
};

export default ChatTag;
