import React, { useEffect, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import styles from "./css/ProjectSettingsPage.module.css";
import { ProductTypes, ProjectData, UpdateProject } from "@/types";
import { useParams } from "react-router-dom";
import { ProjectHeader } from "@/components/headers/ProjectHeader";
import ProjectWorkspace from "@/components/addons/ProjectWorkspace";
import { FieldLink } from "@/components/links/FieldLink";
import { InputField } from "@/components/inputFields/inputFields";
import TextField from "@/components/inputFields/TextFeild";
import { UsersAttachments } from "@/components/pageParts/project/UsersAttachments";
import { UserData } from "@/types/user";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setToast } from "@/store/systemSlice";
import { formatDate } from "@/utils/date";
import { Button } from "@/components/Buttons";
import { useUpdateProject } from "@/hooks/project/useUpdateProject";
import { useFetchAllProjects } from "@/utils/fetchData";
import { useGetAllProjectUsers } from "@/hooks/userProject/useGetAllprojectUsers";
import { useChangeProjectUsersList } from "@/hooks/userProject/useChangeProjectUsersList";
import { LoadingPageAnimation } from "@/components/addons/LoadingPageAnimation";
import { RootState } from "@/store/store";
import { useTranslation } from 'react-i18next';

interface ProjectSettingsProps {}

export const ProjectSettingsPage: React.FC<ProjectSettingsProps> = () => {
  const { t } = useTranslation('ProjectSettingsPage');
  const projects = useSelector((state: RootState) => state.projects.projects);
  const users = useSelector((state: RootState) => state.user.users);
  const org = useSelector((state: RootState) => state.org.userOrg);
  const [fetchedAllData, setFetchedAllData] = useState(false);
  const [projectUsers, setProjectUsers] = useState<UserData[]>([]);
  const dispatch = useDispatch();
  const { projectType, projectId } = useParams<{
    projectType: string;
    projectId: string;
  }>();
  const projectTypes = useSelector(
    (state: RootState) => state.systemService.projectTypes
  );
  const [project, setProject] = useState<ProjectData>();
  const [updateData, setUpdateData] = useState<UpdateProject>({
    project_id: "",
    org_id: org.id,
    description: "",
    name: "",
  });
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingSaveForward, setLoadingSaveForward] = useState(false);
  const navigate = useNavigate();

  const getAllProjects = useFetchAllProjects();

  const getProjectUsers = useGetAllProjectUsers({
    onSuccess(data) {
      setProjectUsers(data);
    },
    onError(error) {
      dispatch(
        setToast({
          toastMessage: error.response.data.message,
          toastTitle: t('FailedGetUsers'),
          toastType: "error",
        })
      );
    },
  });

  const updateProjectUsers = useChangeProjectUsersList({
    onSuccess() {
      updateProject.mutate(updateData as UpdateProject);
    },
    onError(error) {
      dispatch(
        setToast({
          toastMessage: error.response.data.message,
          toastTitle: t('FailedUpdateUsers'),
          toastType: "error",
        })
      );
      setLoadingSave(false);
      setLoadingSaveForward(false);
    },
  });

  useEffect(() => {
    if (project) {
      getProjectUsers
        .mutateAsync({ project_id: project?.id, org_id: org.id })
        .then(() => {
          setFetchedAllData(true);
        });
    }
  }, [project]);

  const updateProject = useUpdateProject({
    onSuccess() {
      dispatch(
        setToast({
          toastTitle: t("UpdateProject"),
          toastMessage: "",
          toastType: "success",
        })
      );
      if (loadingSaveForward) {
        navigate(`https://kalAudioDomain/${project?.id}`);
      }
      setLoadingSave(false);
      setLoadingSaveForward(false);
      getAllProjects.mutate({ org_id: updateData.org_id });
      navigate(`/${org.org_name}/dashboard`);
    },
    onError(error) {
      dispatch(
        setToast({
          toastTitle: t("FailedUpdateProject"),
          toastMessage: error.response.data.message,
          toastType: "error",
        })
      );
      setLoadingSave(false);
      setLoadingSaveForward(false);
    },
  });

  const handleRemoveUser = (user: UserData) => {
    setProjectUsers((prevArray) =>
      prevArray.filter((currUser) => currUser.id !== user.id)
    );
  };

  const handleAddUser = (user: UserData) => {
    setProjectUsers((prevArray) => [...prevArray, user]);
    return true;
  };

  useEffect(() => {
    const selectedProject = projects.find(
      (project) => project.id === projectId
    );
    if (selectedProject) {
      setProject(selectedProject);
      setUpdateData({
        org_id: selectedProject.org_id,
        project_id: selectedProject.id,
        description: selectedProject.description,
        name: selectedProject.name,
      });
    } else {
      navigate("/404");
    }
  }, [projectId]);

  const handleUpdate = (type: string) => {
    if (type === "save") {
      setLoadingSave(true);
    } else {
      setLoadingSaveForward(true);
    }
    if (project) {
      updateProjectUsers.mutate({
        project_id: project.id,
        org_id: org.id,
        users_list: projectUsers,
      });
    }
  };

  const handleUpdateData = (field: string, value: string) => {
    setUpdateData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <div className={styles.container}>
      <ProjectHeader type={projectType as ProductTypes} />
      <ProjectWorkspace projectType={projectType as ProductTypes}>
        {fetchedAllData ? (
          <div className={styles.inner}>
            <div className={styles.settings}>
              <div className={styles.top}>
                <div className={styles.projectName}>{project?.name}</div>
                <div className={`${styles.dotStatus}`} />
                <div
                  className={`${styles.textStatus} ${styles[project?.product_name as string]}`}
                >
                  {project?.status}
                </div>
                <div className={`${styles.creatorDate}`}>
                  <div className={`${styles.creator}`}>
                    {project?.created_by}
                  </div>
                  <div className={`${styles.date}`}>
                    {formatDate(project?.created_at || "")}
                  </div>
                </div>
              </div>
              <div className={styles.link}>
                <div className={styles.fieldTile}>{t('ProjectLink')}</div>
                <FieldLink
                  size="xl"
                  onClick={() => {}}
                  link={project?.link as string}
                />
              </div>
              <div className={styles.bottom}>
                <div className={styles.bottomCol1}>
                  <div className={styles.fieldTile}>{t('ProjectType')}</div>
                  <InputField
                    data={
                      projectTypes.find(
                        (project_type) =>
                          project_type.id === project?.project_type_id
                      )?.type as string
                    }
                    disabled={true}
                    inputType="text"
                    id="1"
                    setData={() => {}}
                  />
                  <div className={styles.usersSection}>
                    <UsersAttachments
                      users={users}
                      selectedUsers={projectUsers}
                      handleAddUser={handleAddUser}
                      handleRemoveUser={handleRemoveUser}
                    />
                  </div>
                </div>
                <div className={styles.bottomCol2}>
                  <div className={styles.fieldTile}>{t("ProjectDescription")}</div>
                  <TextField
                    placeholder={t('Description')}
                    text={updateData.description as string}
                    setText={(value) => handleUpdateData("description", value)}
                  />
                </div>
              </div>
            </div>
            <div className={styles.buttons}>
              <Button
                icon="no"
                text={t('Save')}
                onClick={() => handleUpdate("save")}
                disabled={loadingSaveForward}
                loading={loadingSave}
                id={"save-settings-btn"}
              />
              <Button
                icon="right-side"
                text={t('SaveEnterProject')}
                iconComponent={IoIosArrowForward}
                onClick={() => handleUpdate("forward")}
                ready={true}
                disabled={loadingSave}
                loading={loadingSaveForward}
                id={"save-eneter-settings-btn"}
              />
            </div>
          </div>
        ) : (
          <LoadingPageAnimation />
        )}
      </ProjectWorkspace>
    </div>
  );
};
