import React from "react";
import styles from "./css/ImageView.module.css";
import { DownloadFileIcon } from "@/icons/DownloadFileIcon";
import { FileData, TaskData, Word } from "@/types";
import { FullScreenIcon } from "@/icons/FullScreenIcon";
import { useTranslation } from "react-i18next";
import { FileHeader } from "../headers/FileHeader";
import { ResultsView } from "./ResultsView";

interface Props {
  file: FileData;
  task: TaskData | undefined;
}

export const ImageView: React.FC<Props> = ({ file, task }) => {
  const { t } = useTranslation("ImageView");

  const handleDownload = () => {
    // Determine the file name
    const fileName = file.name || file.id;

    // Extract the file type from the path
    const fileType = file.path.split(".")[1];

    // Create a link element
    const link = document.createElement("a");
    link.href = file.url;
    link.download = `${fileName}.${fileType}`; // Set the download attribute with the file name and type

    // Append to the body (necessary for Firefox)
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up and remove the link element
    document.body.removeChild(link);
  };

  return (
    <div className={`${styles.container}`}>
      <div className={styles.imageContainer}>
        <div
          className={styles.backgroundLayer}
          style={{
            backgroundImage: `url(${file.url})`,
          }}
        ></div>
        <FileHeader file={file} />
        <div className={styles.actions}>
          <div className={styles.actionIcon} onClick={() => {handleDownload()}}>
            <DownloadFileIcon />
          </div>
          <div className={styles.actionIcon} onClick={() => {}}>
            <FullScreenIcon />
          </div>
        </div>
        <div className={styles.imageWrapper}>
          <img src={file.url} alt={file.name} className={`${styles.image}`} />
        </div>
      </div>
      <ResultsView product="KalMedia" file={file} task={task}/>
    </div>
  );
};
