// src/store/userSlice.ts
import { createSlice } from '@reduxjs/toolkit';
import secureLocalStorage from 'react-secure-storage';
import { getStoredData } from '@/utils/store';
import { FileData } from '@/types';

interface FilesState {
  allFiles: FileData[]
  files: FileData[]
  mediaFiles: FileData[]
  filesFetched: boolean
}

  const initialState: FilesState = {
    allFiles: getStoredData<FileData[]>('allFiles', []),
    files: getStoredData<FileData[]>('files', []),
    mediaFiles: getStoredData<FileData[]>('mediaFiles', []),
    filesFetched: getStoredData<boolean>('filesFetched', false)
  };

const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    setAllFiles: (state, action) => {
        state.allFiles = action.payload;
        state.filesFetched = true;
  
        secureLocalStorage.setItem('allFiles', JSON.stringify(action.payload) || '');
        secureLocalStorage.setItem('filesFetched', JSON.stringify(action.payload) || '');
    },
    setFiles: (state, action) => {
      state.files = action.payload;

      secureLocalStorage.setItem('files', JSON.stringify(action.payload) || '');
    },
    setMediaFiles: (state, action) => {
        const newOrUpdatedMediaFiles = action.payload.filter((file: FileData) => {
            const existingFile = state.mediaFiles.find((f) => f.id === file.id);
            
            // Add the file if it's new, or if the task_done status has changed
            return !existingFile || existingFile.status.task_done !== file.status.task_done;
          });
          
          // Update existing files or add new ones
          const updatedMediaFiles = state.mediaFiles.map((mediaFile) => {
            const updatedFile = newOrUpdatedMediaFiles.find((file: FileData) => file.id === mediaFile.id);
            return updatedFile ? updatedFile : mediaFile; // Replace if an updated file exists
          });
          
          // Add files that are completely new (i.e., not in the current state)
          const completelyNewFiles = newOrUpdatedMediaFiles.filter(
            (file: FileData) => !state.mediaFiles.some((mediaFile) => mediaFile.id === file.id)
          );
          
          // Combine updated files with completely new ones
          state.mediaFiles = [...updatedMediaFiles, ...completelyNewFiles];
          
          secureLocalStorage.setItem('mediaFiles', JSON.stringify(state.mediaFiles) || '');
    },
    clearFiles: (state) => {
      state.files = [];
      state.allFiles = [];
      state.mediaFiles = [];
      state.filesFetched = false;

      secureLocalStorage.removeItem('allFiles');
      secureLocalStorage.removeItem('files');
      secureLocalStorage.removeItem('mediaFiles');
      secureLocalStorage.removeItem('filesFetched');
    },
  },
});

export const { setAllFiles, setFiles, setMediaFiles, clearFiles } = filesSlice.actions;
export default filesSlice.reducer;
