import React, { useEffect, useState } from "react";
import styles from "./css/UserModal.module.css";
import { UserProjectsTable } from "../tables/UserProjectsTable";
import { UserData } from "@/types/user";
import {
  Button,
  // DeleteButton,
  // PauseButton,
} from "@/components/Buttons";
// import { UploadPictureCard } from "../cards/UploadPictureCard";
import { InputField } from "../inputFields/inputFields";
import SelectField from "../inputFields/SelectField";
import { ProjectData, RoleData, UpdateUser } from "@/types";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { useUpdateUser } from "@/hooks/user/useUpdateUser";
import { useGetAllUserProjects } from "@/hooks/userProject/useGetAllUserProjects";
import { setToast } from "@/store/systemSlice";
import { useChangeUserProjectsList } from "@/hooks/userProject/useChangeUserProjectsList";
import { RootState } from "@/store/store";
import { useFetchAllUsers } from "@/utils/fetchData";
import { useTranslation } from 'react-i18next';

interface UserModalProps {
  user: UserData;
  users: UserData[];
  setUser: () => void;
  onClose: () => void;
}

export const UserModal: React.FC<UserModalProps> = ({ user, users, onClose }) => {
  const { t } = useTranslation('UserModal');
  const dispatch = useDispatch();
  const roles = useSelector((state: RootState) => state.systemService.roles);
  const [projects, setProjects] = useState<ProjectData[]>([]);
  const [allDataFetched, setAllDataFetched] = useState(false);
  const [updateDone, setUpdateDone] = useState(false);
  const [projectsUpdateDone, setProjectsUpdateDone] = useState(false);
  const [updateProjectsNeeded, setUpdateProjectsNeeded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    "user_name": ""
  })

  const getAllUsers = useFetchAllUsers();

  const updateUserMutation = useUpdateUser({
    onSuccess: () => {
      setUpdateDone(true);
    },
    onError: (error) => {
      dispatch(
        setToast({
          toastMessage: error.response.data.message,
          toastTitle: t("FailedUpdateUser"),
          toastType: "error",
        })
      );
      setLoading(false);
    },
  });

  const [userData, setUserData] = useState<UpdateUser>({
    user_id: user.id,
    org_id: user.org_id,
    first_name: undefined,
    last_name: undefined,
    role_id: user.role_id || "",
    user_name: null || "",
    user_image: null,
    status: user.status as "Active" | "On Hold",
    upload_image_action: "none",
  });

  const handleUpdate = () => {
    setLoading(true);
    setUpdateDone(false);
    setProjectsUpdateDone(false);
    if (updateProjectsNeeded) {
      changeUserProjects.mutate({
        user_id: user.id,
        org_id: user.org_id as string,
        projects_list: projects,
      });
    }
    updateUserMutation.mutate(userData);
  };

  const getUserProjects = useGetAllUserProjects({
    onSuccess(data) {
      setProjects(data);
      setAllDataFetched(true);
    },
    onError(error) {
      dispatch(
        setToast({
          toastMessage: error.response.data.message,
          toastTitle: t("FailedFetchUserProjects"),
          toastType: "error",
        })
      );
    },
  });

  const changeUserProjects = useChangeUserProjectsList({
    onSuccess() {
      setProjectsUpdateDone(true);
    },
    onError(error) {
      dispatch(
        setToast({
          toastMessage: error.response.data.message,
          toastTitle: t("FailedUpdateUserProjects"),
          toastType: "error",
        })
      );
      setLoading(false);
    },
  });

  useEffect(() => {
    getUserProjects.mutate({ org_id: user.org_id as string, user_id: user.id });
  }, [user]);

  useEffect(() => {
    if ((projectsUpdateDone || !updateProjectsNeeded) && updateDone) {
      getAllUsers.mutate({
        org_id: userData.org_id as string,
      });
      onClose();
      dispatch(
        setToast({
          toastMessage: "",
          toastTitle: t("UpdateUserSuccessfully"),
          toastType: "success",
        })
      );
      setUpdateProjectsNeeded(false);
    }
  }, [updateDone, updateProjectsNeeded]);

  const updateRole = (role: RoleData | undefined) => {
    setUserData((prevData) => ({
      ...prevData,
      role_id: role ? role.id : "",
    }));
  };

  const handleDataChange = (field: string, value: string) => {
    const isUserNameTaken = field === "user_name" && users.some(user => user.user_name.toLowerCase() === value.toLowerCase());
    
    setErrors(prev => ({
      ...prev,
      [field]: isUserNameTaken ? t("UsernameExists") : ""
    }));

    setUserData(prevData => ({ ...prevData, [field]: value }));
  };

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <AiOutlineClose className={styles.closeButton} onClick={onClose} />
        <p className={styles.username}>
          <span className={styles.usernameText}>{user.user_name}</span>
          {/* <PauseButton
            onClick={toggleStatus}
            active={user.status === "On Hold"}
          />
          <DeleteButton onClick={() => {}} /> */}
        </p>
        <div className={styles.rows}>
          <div className={`${styles.column} ${styles.left}`}>
            <div className={styles.inputLabel}>
              <label htmlFor="userRole">{t('UserRole')}</label>
              <SelectField
                data={roles}
                selectedValue={
                  roles.find((role) => role.id === userData.role_id) as RoleData
                }
                updateFunction={updateRole}
              />
            </div>
            <div className={styles.inputLabel}>
              <label htmlFor="fullName">{t('FullName')}</label>
              <InputField
                inputType="text"
                data={
                  (userData.first_name || user.first_name) +
                  " " +
                  (userData.last_name || user.last_name)
                }
                setData={(value) => handleDataChange("full_name", value)}
                id="fullName"
                disabled={true}
              />
            </div>
            <div className={styles.inputLabel}>
              <label htmlFor="username">{t('UserName')}</label>
              <InputField
                data={userData.user_name || user.user_name}
                setData={(value) => handleDataChange("user_name", value)}
                inputType="text"
                id="username"
                errorMessage={errors["user_name"]}
              />
            </div>
            <div className={styles.inputLabel}>
              <label htmlFor="useremail">{t('Email')}</label>
              <InputField
                inputType="text"
                data={user.email}
                setData={(value) => handleDataChange("email", value)}
                id="useremail"
                disabled={true}
              />
            </div>
          </div>
          <div className={styles.column}>
            <p className={styles.title}>{t('PinnedProjects')}</p>
            <UserProjectsTable
              data={projects}
              setData={setProjects}
              fetched={allDataFetched}
              setNeededFetch={setUpdateProjectsNeeded}
            />
            <div className={styles.buttonContainer}>
              <Button
                icon="no"
                text={t("SaveUpdate")}
                ready={true}
                onClick={handleUpdate}
                className={styles.button}
                loading={loading}
                disabled={Object.values(errors).some((error) => error !== "")}
                id={"save-update-btn"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
