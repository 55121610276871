import React from "react";
import styles from "./css/Button.module.css";

interface Props {
  icon: "left-side" | "no" | "right-side";
  iconComponent?: React.ElementType;
  iconSrc?: string;
  className?: string;
  text: string;
  disabled?: boolean;
  ready?: boolean;
  iconColorType?: "stroke" | "fill" | "text";
  size?: "small" | "large";
  loading?: boolean; 
  id: string;
  onClick: () => void;
}

export const Button: React.FC<Props> = ({
  icon,
  iconComponent: IconComponent,
  iconSrc,
  className,
  text,
  disabled,
  ready,
  size,
  iconColorType,
  loading,
  id,
  onClick,
}) => {
  const handleClick = () => {
    if (!loading && !disabled) {
      onClick();
    }
  };
  return (
    <div
      className={`${styles.button} ${loading ? styles.loading : ""} ${disabled ? styles.buttonDisabled : ready ? styles.buttonReady : ""} ${className} ${size === "small" ? styles.small : size === "large" ? styles.large : ""}`}
      onClick={handleClick}
      data-testid={id}
    >
      <>
        {loading && (
          <div className={styles.loaderContainer}>
            <div
              className={`${styles.loader} ${ready ? styles.ready : ""}`}
            ></div>
          </div>
        )}
        {icon === "left-side" && (IconComponent || iconSrc) && (
          <div className={styles.iconContainer}>
            {IconComponent ? (
              <IconComponent
                className={`${styles.icon} ${loading ? styles.notShow : ""} ${iconColorType === "fill" ? styles.fillColor : iconColorType === "stroke" ? styles.strokeColor : styles.textColor} ${disabled ? styles.iconDisabled : ready ? styles.iconReady : ""}`}
              />
            ) : (
              <img
                src={iconSrc}
                alt=""
                className={`${styles.icon} ${loading ? styles.notShow : ""} ${iconColorType === "fill" ? styles.fillColor : iconColorType === "stroke" ? styles.strokeColor : styles.textColor} ${disabled ? styles.iconDisabled : ready ? styles.iconReady : ""}`}
              />
            )}
          </div>
        )}

        <div
          className={`${styles.text} ${disabled ? styles.disableText : ready ? styles.textReady : ""} ${loading ? styles.notShow : ""}`}
        >
          {text}
        </div>

        {icon === "right-side" && (IconComponent || iconSrc) && (
          <div className={styles.iconContainer}>
            {IconComponent ? (
              <IconComponent
                className={`${styles.icon} ${loading ? styles.notShow : ""} ${disabled ? styles.iconDisabled : ready ? styles.iconReady : ""} ${iconColorType === "fill" ? styles.fillColor : iconColorType === "stroke" ? styles.strokeColor : styles.textColor}`}
              />
            ) : (
              <img
                src={iconSrc}
                alt=""
                className={`${styles.icon} ${loading ? styles.notShow : ""} ${disabled ? styles.iconDisabled : ready ? styles.iconReady : ""} ${iconColorType === "fill" ? styles.fillColor : iconColorType === "stroke" ? styles.strokeColor : styles.textColor}`}
              />
            )}
          </div>
        )}
      </>
    </div>
  );
};
