import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { EnterIcon } from "@/icons/EnterIcon";
import { FocusIcon } from "@/icons/FocusIcon";
import styles from "./css/ChatInput.module.css";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { langDirection } from "@/utils/languages";
import { SelectedFocus } from "./SelectedFocus";
import { setChatState } from "@/store/chatSlice";

interface ChatInputProps {
  userName: string;
  inputValue: string;
  setInputValue: (value: string) => void;
  onSubmit: (message: string) => void; // Removed file and product parameters
  onFocus: (value: boolean) => void;
}

const ChatInput: React.FC<ChatInputProps> = ({
  userName,
  inputValue,
  setInputValue,
  onSubmit,
  onFocus,
}) => {
  const location = useLocation();
  const decodedPathname = decodeURIComponent(location.pathname);
  const [isFocused, setIsFocused] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { t, i18n } = useTranslation("ChatInput");
  const currentLanguage = i18n.language;
  const isRtl = langDirection(currentLanguage) === "rtl";
  const dispatch = useDispatch();

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && (e.metaKey || e.shiftKey)) {
      e.preventDefault();
      setInputValue(inputValue + "\n");
    } else if (e.key === "Enter" && !e.shiftKey && !e.metaKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (inputValue.trim()) {
      onSubmit(inputValue);
      setInputValue("");
    }
  };

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [inputValue]);

  const handleExitFocus = () => {
    if(inMedia){
      dispatch(setChatState({ chatSearchFocus: "media" }));
    }else {
      dispatch(setChatState({ chatSearchFocus: "none" }));
    }
  };

  const orgName = useSelector((state: RootState) => state.org.userOrg.org_name);
  const chat = useSelector((state: RootState) => state.chat);
  const isDashboardPath = (decodedPathname === `/${orgName}/dashboard`) || (decodedPathname === `/${orgName}/media/files`);
  const inMedia = decodedPathname === `/${orgName}/media/files`;
  return (
    <div className={styles.wrapper}>
      <div className={styles.inputWrapper}>
        <div className={styles.inputContainer}>
          {!inputValue && !isFocused && (
            <div
              className={`${styles.placeholder} ${isRtl ? styles.placeholderH : styles.placeholderE}`}
            >
              <span className={styles.boldUserName}>{userName}</span>
              <span>
                {isRtl ? ` ${t("spantext")}?` : `, ${t("spantext")}?`}
              </span>
            </div>
          )}
          <textarea
            ref={textareaRef}
            className={styles.input}
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onFocus={() => {
              setIsFocused(true);
              onFocus(false); // Hide prompt when focused
            }}
            onBlur={() => setIsFocused(false)}
            rows={1}
          />
        </div>
        <div
          className={`${styles.btn} ${
            inputValue ? styles.visible : styles.invisible
          }`}
          onClick={handleSubmit}
        >
          <EnterIcon
            className={`${styles.btnIcon} ${isRtl ? styles.rtl : ""}`}
          />
        </div>
        {isDashboardPath && (
          <div className={styles.btn}>
            {chat.chatSearchFocus === "none" || (inMedia && chat.chatSearchFocus === "media") ? (
              <div onClick={() => onFocus(true)}>
                <FocusIcon className={styles.btnIcon} />
              </div>
            ) : (
              <SelectedFocus
                onClose={() => handleExitFocus()}
                selectedFocus={chat.chatSearchFocus}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatInput;
