import React, { useEffect, useState } from "react";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";

const ProtectedAccess: React.FC = () => {
  const { orgName } = useParams<{ orgName: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const decodedPathname = decodeURIComponent(location.pathname);
  const user = useSelector((state: RootState) => state.user.user);
  const org = useSelector((state: RootState) => state.org.userOrg);
  const isAuthenticated = user.user_type !== "";
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const checkAccess = () => {
      if (!isAuthenticated) {
        console.log("navigate to login beacus: is auth is" + isAuthenticated);

        navigate(`/${orgName}`);
        return;
      }

      const allowedUserRoutes = [
        `/${orgName}/dashboard`,
        `/${orgName}/KalAudio`,
        `/${orgName}/KalChat`,
        `/${orgName}/KalDocs`,
        `/${orgName}/KalMedia`,
        `/${orgName}/user/settings`,
        `/${orgName}/doc/files`,
        `/${orgName}/audio/files`,
        `/${orgName}/media/files`,
        `/${orgName}/doc/file/`,
        `/${orgName}/audio/file/`,
        `/${orgName}/media/file/`,
        `/${orgName}/search-res`,
      ];

      const notAllowedSuper = [`/${orgName}/project`];
      const notAllowedAdmin = [`/${orgName}/organization`];

      const pathMatch =
        (!allowedUserRoutes.some((path) => decodedPathname.startsWith(path)) &&
          user.user_type === "user") ||
        (notAllowedSuper.some((path) => decodedPathname.startsWith(path)) &&
          user.user_type === "super_admin") ||
        (notAllowedAdmin.some((path) => decodedPathname.startsWith(path)) &&
          user.user_type === "admin") ||
        ((decodedPathname !== `/${orgName}` ||
          decodedPathname !== `/${orgName}/`) &&
          org.org_name.toLowerCase() !== orgName?.toLowerCase());

      if (pathMatch) {
        navigate("/403");
      } else {
        setIsChecking(false);
      }
    };

    checkAccess();
  }, [location.pathname, user, org, orgName, isAuthenticated, navigate]);

  if (!isAuthenticated) {
    console.log("navigate to login beacus: is auth is" + isAuthenticated);
    return <Navigate to={`/${orgName}`} />;
  }

  if (isChecking) {
    return null;
  }

  return <Outlet />;
};

export default ProtectedAccess;