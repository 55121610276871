import { FileData } from "@/types";
import React, { useEffect, useState } from "react";
import styles from "./css/ImageCard.module.css";
import { useNavigate, useParams } from "react-router-dom";
import ImageGeneratingAnimation from "../pageParts/ImageGeneratingAnimation";
import { useTranslation } from "react-i18next";
import { langDirection } from "@/utils/languages";
import { InProcessingIcon } from "@/icons/InProcessingIcon";

interface CardProps {
  file: FileData;
  index: number;
}

export const ImageCard: React.FC<CardProps> = ({ file, index }) => {
  const navigate = useNavigate();
  const { orgName } = useParams<{ orgName: string }>();
  const { t } = useTranslation("FileCard");
  const { i18n } = useTranslation("LanguageSwitcher");
  const currentLanguage = i18n.language;
  const direction = langDirection(currentLanguage);

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const imageSource = file.thumbnail || file.url;

  const onClick = () => {
    navigate(`/${orgName}/media/file/${file.id}`);
  };

  useEffect(() => {
    // Reset loading state when file changes
    setIsLoading(true);
    setHasError(false);
  }, [file]);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
    setHasError(true);
  };

  return (
    <div>
      {(isLoading || !imageSource) && file.status.task_done && <ImageGeneratingAnimation size="sm" />}
      {hasError && imageSource && <div>Error loading image</div>}
      {file.status.task_done ? (
        <img
          key={index}
          src={imageSource}
          alt={`Media ${index + 1}`}
          className={styles.mediaItem}
          onClick={onClick}
          onLoad={handleImageLoad}
          onError={handleImageError}
          style={{ display: isLoading ? "none" : "block" }}
        />
      ) : (
        <>
          <div className={styles.process}>
            <InProcessingIcon
              className={`${styles.processing} ${styles.iconProcessing} ${direction === "rtl" ? styles.rtl : ""}`}
            />
            <div
              className={`${styles.typeTextProcessing} ${styles.processing} ${direction === "rtl" ? styles.rtl : ""}`}
            >
              {t("processing")}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
