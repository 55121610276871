import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './css/LanguageSwitcher.module.css';
import translationButtonHeaderSvg from "@/assets/translationButtonHeader.svg";
import { langDirection } from '@/utils/languages';

const LanguageSwitcher: React.FC = () => {
  const { i18n, t } = useTranslation('LanguageSwitcher');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const isRtl = i18n.language === 'he'; 

  const handleLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('i18nextLng', lang);
    document.documentElement.dir = langDirection(lang);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    document.documentElement.dir = langDirection(i18n.language)
  }, [i18n.language]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.languageSwitcher} ref={dropdownRef}>
      <button
        value={i18n.language}
        className={styles.container}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <img src={translationButtonHeaderSvg} alt="translation button header" />
      </button>

      {isDropdownOpen && (
        <div className={`${styles.dropdownMenu} ${isRtl ? styles['rtl-dropdown'] : styles['ltr-dropdown']}`}>
          <div className={`${styles.dropdownItem} ${i18n.language === "en"? styles.selected: ""}`} onClick={() => handleLanguageChange('en')}>
            {t('English')}
          </div>
          <div className={`${styles.dropdownItem} ${i18n.language === "he"? styles.selected: ""}`} onClick={() => handleLanguageChange('he')}>
            {t('Hebrew')}
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
