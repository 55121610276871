import React from 'react';
import SearchMessage from './SearchMessage';
import styles from './css/SearchRes.module.css';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { useTranslation } from 'react-i18next';

interface SearchResProps {

}

const SearchRes: React.FC<SearchResProps> = () => {
  const chat = useSelector((state: RootState) => state.chat);
  const { t } = useTranslation('SearchRes');

  return (
    <div className={styles.container}>
      <div className={styles.mainContent}>
        <div className={styles.welcomeMessage}>{chat.chatSearchFocus === "generate"? t('imageMessage'): t('searchMessage')}</div>
        <div className={styles.continueMessage}>
            <div style={{ direction: /[\u0590-\u05FF]/.test(chat.chatQuery) ? 'rtl' : 'ltr' }}>{chat.chatQuery}</div>
        </div>
        <div className="p-1">
        <SearchMessage />
        </div>
      </div>
      <div>
      </div>
    </div>
  );
};

export default SearchRes;