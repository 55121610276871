import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import styles from "./css/FilesPage.module.css";
import { FileCard } from "@/components/cards/FileCard";
import { GridViewIcon } from "@/icons/GridView";
import { TableViewIcon } from "@/icons/TableView";
import FilesTable from "@/components/tables/FilesTable";
import { FileData, ProjectColors } from "@/types";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setFiles, setMediaFiles } from "@/store/filesSlice";
import { setChatState } from "@/store/chatSlice";
import { useFetchAllFiles } from "@/utils/fetchData";
import { ImageCard } from "@/components/cards/ImageCrad";
import { useGetFile } from "@/hooks/files/useGetFile";
import { LoadingPageAnimation } from "@/components/addons/LoadingPageAnimation";
import { useTranslation } from "react-i18next";
import { langDirection } from "@/utils/languages";

export const FilesPage: React.FC = () => {
  const { i18n } = useTranslation("LanguageSwitcher");
  const currentLanguage = i18n.language;
  const direction = langDirection(currentLanguage);
  const { files, mediaFiles, filesFetched } = useSelector(
    (state: RootState) => state.files
  );
  const dispatch = useDispatch();
  const allFiles = useSelector((state: RootState) => state.files.allFiles);
  const getAllFiles = useFetchAllFiles();
  const orgId = useSelector((state: RootState) => state.org.userOrg?.id);
  const chat = useSelector((state: RootState) => state.chat);
  const projects = useSelector((state: RootState) => state.projects.projects);
  const { type } = useParams<{ type: string }>();
  const allProjects = useSelector(
    (state: RootState) => state.projects.projects
  );
  const [view, setView] = useState<"grid" | "table">("grid");
  const colors = ["green", "pink", "yellow", "blue"];
  const randomColor = () => colors[Math.floor(Math.random() * colors.length)];
  const projectId = projects.length > 0 ? projects[0].id : "null";

  const getFile = useGetFile();

  useEffect(() => {
    const productType = type === "doc" ? "KalDocs" : "KalAudio";
    if (chat.responseFilteredFiles && chat.responseFilteredFiles.length > 0) {
      dispatch(
        setFiles(
          chat.responseFilteredFiles.filter(
            (file) => file.product === productType
          )
        )
      );
    } else if (type === "doc") {
      dispatch(setFiles(allFiles.filter((file) => file.product === "KalDocs")));
      dispatch(setChatState({ chatSearchFocus: "doc" }));
    } else if (type === "audio") {
      dispatch(
        setFiles(allFiles.filter((file) => file.product === "KalAudio"))
      );
      dispatch(setChatState({ chatSearchFocus: "audio" }));
    } else if (type === "media") {
      dispatch(
        setFiles(allFiles.filter((file) => file.product === "KalMedia"))
      );
      dispatch(setChatState({ chatSearchFocus: "none" }));
    }
  }, [type, chat.responseFilteredFiles]);

  useEffect(() => {
    if (type === "doc") {
      dispatch(setFiles(allFiles.filter((file) => file.product === "KalDocs")));
      dispatch(setChatState({ chatSearchFocus: "doc" }));
    } else if (type === "audio") {
      dispatch(
        setFiles(allFiles.filter((file) => file.product === "KalAudio"))
      );
      dispatch(setChatState({ chatSearchFocus: "audio" }));
    } else if (type === "media") {
      dispatch(
        setFiles(allFiles.filter((file) => file.product === "KalMedia"))
      );
      dispatch(setChatState({ chatSearchFocus: "none" }));
    }
  }, [type]);

  const filesArray = Array.isArray(files) ? files : [];

  useEffect(() => {
    // Add interval to call getAllFiles every 30 seconds
    const interval = setInterval(() => {
      getAllFiles.mutate({
        project_id: projectId,
        org_id: orgId,
      });
    }, 10000);
    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [type, getAllFiles]);

  useEffect(() => {
    if (chat.responseFilteredFiles && chat.responseFilteredFiles.length > 0) {
      return;
    }
    if (type === "doc") {
      dispatch(setFiles(allFiles.filter((file) => file.product === "KalDocs")));
    } else if (type === "audio") {
      dispatch(
        setFiles(allFiles.filter((file) => file.product === "KalAudio"))
      );
    } else if (type === "media") {
      if (mediaFiles.length > 0) {
        const mediaFilesNotFetchedOrUpdated = allFiles.filter((file) => {
          if (file.product !== "KalMedia") return false; // Only interested in KalMedia

          const existingFile = mediaFiles.find((f) => f.id === file.id);

          // File is not in mediaFiles or its task_done status differs
          return (
            !existingFile ||
            existingFile.status.task_done !== file.status.task_done
          );
        });

        if (mediaFilesNotFetchedOrUpdated.length > 0) {
          const fetchPromises = mediaFilesNotFetchedOrUpdated.map((file) =>
            getFile.mutateAsync({
              file_id: file.id,
              org_id: file.org_id,
              project_id: file.project_id,
            })
          );

          // Wait for all the fetches to complete
          Promise.all(fetchPromises)
            .then((fetchedFiles) => {
              // Combine the fetched files with any existing media files
              const newFiles = [...fetchedFiles];
              dispatch(setMediaFiles(newFiles)); // Dispatch the updated media files
            })
            .catch((error) => {
              console.error("Error fetching files:", error);
            });
        }
      }
    }
  }, [allFiles, chat.responseFilteredFiles]);

  return (
    <div className={styles.container}>
      <div className={`${styles.filterWrapper} ${styles[direction]}`}>
        {type !== "media" && (
          <div className={styles.filter}>
            <div onClick={() => setView("grid")}>
              <GridViewIcon
                containerColor={`${styles.viewContainer} ${view === "grid" ? styles.selected : ""}`}
                gridColor={`${styles.viewIcon} ${view === "grid" ? styles.selected : ""}`}
                className={styles.view}
              />
            </div>
            <div onClick={() => setView("table")}>
              <TableViewIcon
                containerColor={`${styles.viewContainer} ${view === "table" ? styles.selected : ""}`}
                tableColor={`${styles.viewIcon} ${view === "table" ? styles.selected : ""}`}
                className={styles.view}
              />
            </div>
          </div>
        )}
      </div>
      {!filesFetched ? (
        <div className={styles.animation}>
          <LoadingPageAnimation />
        </div>
      ) : (
        <>
          {view === "grid" ? (
            type === "media" ? (
              <div className={`${styles.gridView} ${styles[direction]}`}>
                {mediaFiles.length > 0
                  ? mediaFiles.map((file, index) => (
                      <ImageCard file={file} index={index} key={index} />
                    ))
                  : files
                      .filter((file) => file.product === "KalMedia")
                      .map((file, index) => (
                        <ImageCard file={file} index={index} key={index} />
                      ))}
              </div>
            ) : (
              <div className={`${styles.gridView} ${styles[direction]}`}>
                {filesArray.map((file, index) => {
                  const project = allProjects.find(
                    (project) => project.id === file.project_id
                  );
                  return (
                    <FileCard
                      key={index}
                      projectName={project?.name || "תמלול ועדה רפואית"}
                      projectColor={
                        project?.color || (randomColor() as ProjectColors)
                      }
                      data={file}
                    />
                  );
                })}
              </div>
            )
          ) : (
            <div className={`${styles.tableView} ${styles[direction]}`}>
              <FilesTable data={filesArray} />
            </div>
          )}
        </>
      )}
    </div>
  );
};
