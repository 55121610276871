import React from "react";
import styles from "./css/FileHeader.module.css";
import { FileData } from "@/types";
import { ProjectTag } from "../tags/ProjectTag";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { useTranslation } from "react-i18next";

interface Props {
  file: FileData;
}

export const FileHeader: React.FC<Props> = ({ file }) => {
  const allProjects = useSelector(
    (state: RootState) => state.projects.projects
  );
  const project = allProjects.find((project) => project.id === file.project_id);
  const { t } = useTranslation('FileHeader');
  return (
    <div className={`${styles.container} text-white ${styles[file.product]}`}>
      {file.product !== "KalMedia" && (
        <>
          <ProjectTag
            color={project?.color || "green"}
            name={project?.name || "תמלול ועדה רפואית"}
            size="lg"
          />
          <div className={styles.title}>{t('FileName')}</div>
          <div className={styles.value}>{file?.name}</div>
        </>
      )}
      <div className={styles.title}>{t('Date')}</div>
      <div className={styles.value}>{file?.created_at?.split(" ")[0]}</div>
    </div>
  );
};
