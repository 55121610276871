import { useMutation } from '@tanstack/react-query'
import { generateImage } from '../../api/files'
import { PostParam } from '@/types'

export function useGenerateImage(props?: PostParam) {
    const { onSuccess, onError } = props || {};

    return useMutation({
        mutationFn: generateImage,
        onSuccess,
        onError
    })
}