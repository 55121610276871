import { BiX } from "react-icons/bi";
import { langDirection } from "@/utils/languages";
import React from "react";
import { useTranslation } from "react-i18next";
import { UserDocsIcon } from "@/icons/UserDocsIcon";
import { UserAudioIcon } from "@/icons/UserAudioIcon";
import { SpakleIcon } from "@/icons/SpakleIcon";
import { UserMediaIcon } from "@/icons/userMediaIcon";
import styles from "./css/SelectedFocus.module.css";

interface SelectedFocusProps {
  onClose: () => void;
  selectedFocus: "audio" | "doc" | "generate" | "media" | "none";
}

export const SelectedFocus: React.FC<SelectedFocusProps> = ({
  selectedFocus,
  onClose,
}) => {
  const { t } = useTranslation("ChatFocus");
  const { i18n } = useTranslation("LanguageSwitcher");
  const currentLanguage = i18n.language;
  const direction = langDirection(currentLanguage);

  const handleClose = () => {
    onClose();
  }

  return (
    <div className={`${styles.container} ${styles[direction]}`}>
      <BiX className={`${styles.exitIcon}`} onClick={handleClose} />
      {selectedFocus === "doc" ? (
        <>
          <div className={`${styles.text}`}>{t("optionTextDoc")}</div>
          <UserDocsIcon className={`${styles.icon} ${styles[direction]}`} />
        </>
      ) : selectedFocus === "audio" ? (
        <>
          <div className={`${styles.text}`}>{t("optionTextAudio")}</div>
          <UserAudioIcon className={`${styles.icon} ${styles[direction]}`} />
        </>
      ) : selectedFocus === "media" ? (
        <>
          <div className={`${styles.text}`}>{t("optionTextMedia")}</div>
          <UserMediaIcon className={`${styles.icon} ${styles[direction]}`} />
        </>
      ) : selectedFocus === "generate" ? (
        <>
          <div className={`${styles.text}`}>{t("optionTextGenerate")}</div>
          <SpakleIcon className={`${styles.icon} ${styles[direction]}`} />
        </>
      ): <></>}
    </div>
  );
};
