import React from "react";
import styles from "./css/ProjectTag.module.css";
import { ProjectColors } from "@/types";
import TruncateText from "../addons/TruncatedText";
import { useTranslation } from "react-i18next";
import { langDirection } from "@/utils/languages";

interface Props {
  color: ProjectColors;
  name?: string;
  disabled?: boolean;
  size?: "sm" | "lg"
}

export const ProjectTag: React.FC<Props> = ({
  color,
  disabled,
  name,
  size = "sm"
}) => {

    const { i18n } = useTranslation("LanguageSwitcher");
    const currentLanguage = i18n.language;
    const direction = langDirection(currentLanguage);

  return (
    <div
      className={`${styles.container} ${disabled ? styles.disabled : ""} ${styles[color]} ${styles[size]}`}
    >
      <div
        className={`${styles.text} ${disabled ? styles.disabled : ""} ${styles[color]} ${styles[size]}`}
      >
        <div className={`${styles.dot} ${styles[direction]} ${styles[color]}`}></div>
        <TruncateText text={name as string} maxLength={10} />
      </div>
    </div>
  );
};
