import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, CategoryScale, Tooltip, ChartOptions, Plugin } from 'chart.js';

ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale, Tooltip);

interface SentimentData {
  sentence: string;
  start: string;
  end: string;
  sentiment: {
    label: string;
    score: number;
  };
}

interface SentimentsGraphProps {
  sentimentData: SentimentData[];
  currentTime: string;
  onSegmentClick: (time: string) => void;
}

export const SentimentsGraph: React.FC<SentimentsGraphProps> = ({
  sentimentData,
  currentTime,
  onSegmentClick,
}) => {
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);

  const parseTime = (time: string): number => {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return (hours || 0) * 3600 + (minutes || 0) * 60 + (seconds || 0);
  };

  const findClosestIndex = (): number => {
    const currentSeconds = parseTime(currentTime);
    let closestIndex = 0;
    let smallestDifference = Infinity;

    sentimentData.forEach((data, index) => {
      const dataTimeSeconds = parseTime(data.start);
      const difference = Math.abs(dataTimeSeconds - currentSeconds);

      if (difference < smallestDifference) {
        smallestDifference = difference;
        closestIndex = index;
      }
    });

    return closestIndex;
  };

  useEffect(() => {
    const closestIndex = findClosestIndex();
    setHighlightedIndex(closestIndex);
  }, [currentTime]);

  const chartData = {
    labels: sentimentData.map((data, index) =>
      index === highlightedIndex ? data.start : ''
    ),
    datasets: [
      {
        label: 'Sentiment',
        data: sentimentData.map(data => data.sentiment.label === 'positive' ? 1 : data.sentiment.label === 'neutral' ? 0 : -1),
        fill: false,
        borderColor: '#007bff',
        tension: 0.3, 
        pointBackgroundColor: sentimentData.map((_, index) =>
          index === highlightedIndex ? 'red' : 'blue'
        ),
        pointRadius: sentimentData.map((_, index) =>
          index === highlightedIndex ? 6 : 4
        ),
        pointHoverRadius: 8, 
        borderWidth: 2, 
      },
    ],
  };

  const backgroundColorPlugin: Plugin<'line'> = {
    id: 'backgroundColorPlugin',
    beforeDraw: (chart) => {
      const { ctx, chartArea, scales } = chart;
      const yScale = scales.y;

      ctx.save();
      ctx.fillStyle = 'rgba(255, 0, 0, 0.1)';
      ctx.fillRect(chartArea.left, yScale.getPixelForValue(0), chartArea.right - chartArea.left, chartArea.bottom - yScale.getPixelForValue(0));
      ctx.fillStyle = 'rgba(0, 255, 0, 0.1)';
      ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, yScale.getPixelForValue(0) - chartArea.top);
      ctx.restore();
    },
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const elementIndex = elements[0].index;
        const clickedTime = sentimentData[elementIndex].start;
        onSegmentClick(clickedTime);
      }
    },
    scales: {
      x: {
          display: false
      },
      y: {
        title: {
          display: false,
          text: 'Sentiment',
          font: {
            size: 14,
          },
        },
        ticks: {
            callback: function (tickValue: string | number): string | number {
              if (tickValue === 1) return 'Positive';
              if (tickValue === 0) return 'Neutral';
              if (tickValue === -1) return 'Negative';
              return '';
            },
            font: {
              size: 12,
            },
          }
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
      },
    },
  };

  return <Line data={chartData} options={options} plugins={[backgroundColorPlugin]} />;
};
