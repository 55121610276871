import { BiMinus } from "react-icons/bi";
import { BiPlus } from "react-icons/bi";
import { FileData, ProductTypes, TaskData } from "@/types";
import React, { useEffect, useState } from "react";
import styles from "./css/ResultsView.module.css";
import { useTranslation } from "react-i18next";
import { ChatFile } from "./ChatFile";
import { BussinessRuleAcordion } from "../acordion/BussinessRuleAcordion";
import { RuleResults } from "@/types/rules";
import { DownloadFileIcon } from "@/icons/DownloadFileIcon";
import { FullScreenIcon } from "@/icons/FullScreenIcon";
import { SentimentsGraph } from "./SentimentsGraph";
import { langDirection } from "@/utils/languages";

interface Props {
  product: ProductTypes;
  currentTime?: string;
  setCurrentPress?: (currTime: string) => void;
  file: FileData;
  task: TaskData | undefined;
}

export const ResultsView: React.FC<Props> = ({
  file,
  task,
  product,
  currentTime = "",
  setCurrentPress = () => {},
}) => {
  const { t, i18n } = useTranslation("ResultView");
  const direction = langDirection(i18n.language);
  const [chatResults, setChatResults] = useState<
    { question: string; result: string }[]
  >([]);
  const [newChatResultIndex, setNewChatResultIndex] = useState(0);
  const [sentimentData, setSentimentData] = useState<any[]>([]);
  const [shrink, setShrink] = useState(false);
  const [shrinkSummary, setShrinkSummary] = useState(false);
  const [shrinkSentiment, setShrinkSentiment] = useState(false);
  const [shrinkRules, setShrinkRules] = useState(false);
  const [shrinkPrompt, setShrinkPrompt] = useState(false);
  const [shrinkDes, setShrinkDes] = useState(false);
  const [shrinkTag, setShrinkTag] = useState(false);
  const [shrinkChat, setShrinkChat] = useState(false);

  const onNewResult = (result: string) => {
    let index = 0;
    let currentResult = "";

    const typingInterval = setInterval(() => {
      if (index < result.length) {
        currentResult += result[index];
        setChatResults((prev) => {
          const newResults = [...prev];
          newResults[newChatResultIndex]["result"] = currentResult;
          return newResults;
        });

        index++;
      } else {
        clearInterval(typingInterval);
        setNewChatResultIndex((prev) => prev + 1);
      }
    }, 20);
  };

  const onNewQuestion = (value: string) => {
    if (!shrink) {
      setShrink(true);
      setShrinkSummary(true);
      setShrinkSentiment(true);
      setShrinkRules(true);
      setShrinkPrompt(true);
      setShrinkDes(true);
      setShrinkTag(true);
      setShrinkChat(false);
    }
    setChatResults([...chatResults, { question: value, result: "" }]);
  };

  const handleSegmentClick = (time: string) => {
    console.log("Segment Clicked, Raw Time:", time);
    const formattedTime = time + ":000";
    setCurrentPress(formattedTime);
  };

  useEffect(() => {
    if (task && task.results?.sentiment_json) {
      const sentimentArray = Object.values(task.results.sentiment_json);
      setSentimentData(sentimentArray);
    }
  }, [task]);

  return (
    <div>
      <div
        className={`${styles.resultsContainer} ${product === "KalMedia" ? styles.media : ""}`}
      >
        <div
          className={`${styles.aboveChat} ${product === "KalMedia" ? styles.media : ""}`}
        >
          {(product === "KalAudio" || product === "KalDocs") && (
            <>
              <div className={styles.summTitle}>
                <div
                  className={`${direction === "rtl" ? styles.titleTextRtl : styles.titleTextLtr}`}
                >
                  {t("summTitle")}
                </div>
                <div className={`${styles.shrinkIcon}`}>
                  {shrinkSummary ? (
                    <div
                      onClick={() => {
                        setShrinkSummary(false);
                      }}
                    >
                      {" "}
                      <BiPlus />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setShrinkSummary(true);
                      }}
                    >
                      {" "}
                      <BiMinus />
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.summResults}>
                {!shrinkSummary && (
                  <textarea
                    className={`${styles.nonEditableTextareaSummary} ${product === "KalAudio" ? styles.audio : ""}`}
                    readOnly
                    dir={
                      /[\u0590-\u05FF]/.test(task?.results?.summary)
                        ? "rtl"
                        : "ltr"
                    }
                    value={
                      task && task.results?.summary ? task.results.summary : ""
                    }
                  />
                )}
                {product === "KalAudio" && (
                  <>
                    {!shrinkSummary && (
                      <div className={styles.summResult}>
                        <div className={styles.callDuration}>
                          {t("callDuration")}{" "}
                          <div className={styles.duration}>
                            {file?.duration}
                          </div>
                        </div>
                        {/* <Sentimental sentiment="happy" /> */}
                        <div className={styles.summActions}>
                          <div
                            className={styles.summActionIcon}
                            onClick={() => {}}
                          >
                            <DownloadFileIcon />
                          </div>
                          <div
                            className={styles.summActionIcon}
                            onClick={() => {}}
                          >
                            <FullScreenIcon />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className={styles.title}>
                      <div
                        className={`${direction === "rtl" ? styles.senttitleTextRtl : styles.senttitleTextLtr}`}
                      >
                        {t("sentimentTitle")}
                      </div>
                      <div className={`${styles.shrinkIcon}`}>
                        {shrinkSentiment ? (
                          <div
                            onClick={() => {
                              setShrinkSentiment(false);
                            }}
                          >
                            {" "}
                            <BiPlus />
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              setShrinkSentiment(true);
                            }}
                          >
                            {" "}
                            <BiMinus />
                          </div>
                        )}
                      </div>
                    </div>
                    {!shrinkSentiment && (
                      <SentimentsGraph
                        sentimentData={sentimentData}
                        onSegmentClick={handleSegmentClick}
                        currentTime={currentTime}
                      />
                    )}
                  </>
                )}
              </div>
            </>
          )}
          {product === "KalAudio" &&
            task?.results?.business_rules.length > 0 && (
              <div className={styles.rules}>
                <div className={styles.title}>
                  <div
                    className={`${direction === "rtl" ? styles.titleTextRtl : styles.titleTextLtr}`}
                  >
                    {t("rulesTitle")}
                  </div>
                  <div className={`${styles.shrinkIcon}`}>
                    {shrinkRules ? (
                      <div
                        onClick={() => {
                          setShrinkRules(false);
                        }}
                      >
                        {" "}
                        <BiPlus />
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          setShrinkRules(true);
                        }}
                      >
                        {" "}
                        <BiMinus />
                      </div>
                    )}
                  </div>
                </div>
                {!shrinkRules && (
                  <>
                    {" "}
                    <div className={styles.rules}>
                      {task?.results?.business_rules?.map(
                        (rule: RuleResults) => (
                          <BussinessRuleAcordion
                            key={rule.title || ""}
                            rule={rule}
                            setCurrentTime={setCurrentPress}
                          />
                        )
                      )}
                    </div>
                    <div className={styles.bottom}>
                      <div className={styles.summActions}>
                        <div
                          className={styles.summActionIcon}
                          onClick={() => {}}
                        >
                          <DownloadFileIcon />
                        </div>
                        <div
                          className={styles.summActionIcon}
                          onClick={() => {}}
                        >
                          <FullScreenIcon />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          {product === "KalMedia" && (
            <>
              {file.prompt && (
                <>
                  <div className={styles.title}>
                    <div
                      className={`${direction === "rtl" ? styles.titleTextRtl : styles.titleTextLtr}`}
                    >
                      {t("prompt")}
                    </div>
                    <div className={`${styles.shrinkIcon}`}>
                      {shrinkPrompt ? (
                        <div
                          onClick={() => {
                            setShrinkPrompt(false);
                          }}
                        >
                          {" "}
                          <BiPlus />
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            setShrinkPrompt(true);
                          }}
                        >
                          {" "}
                          <BiMinus />
                        </div>
                      )}
                    </div>
                  </div>
                  {!shrinkPrompt && (
                    <textarea
                      className={`${styles.nonEditableTextarea} ${chatResults.length > 0 ? styles.shrink : ""}`}
                      readOnly
                      dir={
                        /[\u0590-\u05FF]/.test(file?.prompt as string)
                          ? "rtl"
                          : "ltr"
                      }
                      value={file && file?.prompt ? file.prompt : ""}
                    />
                  )}
                </>
              )}
              <div className={styles.title}>
                <div
                  className={`${direction === "rtl" ? styles.titleTextRtl : styles.titleTextLtr}`}
                >
                  {t("aiDescriptionTitle")}
                </div>
                <div className={`${styles.shrinkIcon}`}>
                  {shrinkDes ? (
                    <div
                      onClick={() => {
                        setShrinkDes(false);
                      }}
                    >
                      {" "}
                      <BiPlus />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setShrinkDes(true);
                      }}
                    >
                      {" "}
                      <BiMinus />
                    </div>
                  )}
                </div>
              </div>
              {!shrinkDes && (
                <textarea
                  className={`${styles.nonEditableTextarea} ${chatResults.length > 0 ? styles.shrink : ""}`}
                  readOnly
                  dir={
                    /[\u0590-\u05FF]/.test(
                      task?.results?.ai_description as string
                    )
                      ? "rtl"
                      : "ltr"
                  }
                  value={
                    task && task?.results?.ai_description
                      ? task.results.ai_description
                      : ""
                  }
                />
              )}
              {task?.results?.ai_tags?.join(", ") && (
                <>
                  <div className={styles.title}>
                    <div
                      className={`${direction === "rtl" ? styles.titleTextRtl : styles.titleTextLtr}`}
                    >
                      {t("aiTagsTitle")}
                    </div>
                    <div className={`${styles.shrinkIcon}`}>
                      {shrinkTag ? (
                        <div
                          onClick={() => {
                            setShrinkTag(false);
                          }}
                        >
                          {" "}
                          <BiPlus />
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            setShrinkTag(true);
                          }}
                        >
                          {" "}
                          <BiMinus />
                        </div>
                      )}
                    </div>
                  </div>
                  {!shrinkTag && (
                    <textarea
                      className={`${styles.nonEditableTextarea} ${chatResults.length > 0 ? styles.shrink : ""}`}
                      readOnly
                      dir={
                        /[\u0590-\u05FF]/.test(
                          task?.results?.ai_tags?.join(", ")
                        )
                          ? "rtl"
                          : "ltr"
                      }
                      value={
                        task && task.results?.ai_tags
                          ? task.results?.ai_tags?.join(", ")
                          : ""
                      }
                    />
                  )}
                </>
              )}
            </>
          )}
          {chatResults.length > 0 && (
            <div className={styles.chatResults}>
              <div className={styles.title}>
                <div
                  className={`${direction === "rtl" ? styles.titleTextRtl : styles.titleTextLtr}`}
                >
                  {t("chatTitle")}
                </div>
                <div className={`${styles.shrinkIcon}`}>
                  {shrinkChat ? (
                    <div
                      onClick={() => {
                        setShrinkChat(false);
                      }}
                    >
                      {" "}
                      <BiPlus />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        setShrinkChat(true);
                      }}
                    >
                      {" "}
                      <BiMinus />
                    </div>
                  )}
                </div>
              </div>
              {!shrinkChat &&
                chatResults.map((item, index) => (
                  <>
                    <span
                      key={index}
                      className={`${styles.chatQuestion} ${styles[direction]}`}
                    >
                      {item.question}
                    </span>
                    {item.result && (
                      <span
                        key={index}
                        className={`${styles.resultChatText} ${styles[direction]} ${
                          index === newChatResultIndex
                            ? styles.typingEffect
                            : ""
                        }`}
                      >
                        {item.result}
                      </span>
                    )}
                  </>
                ))}
            </div>
          )}
        </div>
        <div className={styles.chat}>
          <ChatFile
            file={file}
            setResults={(value: string) => onNewResult(value)}
            setQuerys={(value: string) => onNewQuestion(value)}
          />
        </div>
      </div>
    </div>
  );
};
