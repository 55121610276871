import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./css/CreateNewOrgCard.module.css";
import { Button } from "@/components/Buttons";
import { Plus } from "../../icons/Plus";
import { CreateOrgIcon } from "@/icons/CreateOrgIcon";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { useTranslation } from 'react-i18next';

const CreateNewOrgCard: React.FC = () => {
  const { t } = useTranslation('CreateNewOrgCard');
  const navigate = useNavigate();
  const org = useSelector((state: RootState) => state.org.userOrg);

  const handleClick = () => {
    navigate(`/${org.org_name}/organization/create`);
  };

  return (
    <div className={`${styles.frameBorder}`}>
      <div className="absolute w-[198px] h-[46px] top-[31px] left-[63px]">
        <div className={styles.title}>{t('CreateOrganization')}</div>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          text={t('CreateNew')}
          icon="right-side"
          iconComponent={Plus}
          onClick={handleClick}
          size="small"
          className="h-[unset] whitespace-nowrap w-fit"
          id={"create-org-btn"}
        />
      </div>
      <div>
        <CreateOrgIcon className="absolute w-[264px] h-10 top-[180px] left-7" />
      </div>
    </div>
  );
};

export default CreateNewOrgCard;
