import React, { useState } from 'react';
import styles from './css/PromptCard.module.css';
import prompt1 from '../../assets/prompt1.png';
import prompt2 from '../../assets/prompt2.png';
import prompt3 from '../../assets/prompt3.png';
import { Button } from "../Buttons";
import { useTranslation } from 'react-i18next';


const extractText = (node: React.ReactNode): string => {
  if (typeof node === 'string') return node;
  if (Array.isArray(node)) return node.map(extractText).join('');
  if (React.isValidElement(node)) return extractText(node.props.children);
  return '';
};

interface Stage {
  title: string;
  description: React.ReactNode;
  buttonBackground: string;
}

interface PromptCardProps {
  onClick: (promptId: string, promptText: string) => void;
}

const PromptCard: React.FC<PromptCardProps> = ({ onClick }) => {
  const [activeStage, setActiveStage] = useState(0);
  const { t } = useTranslation('PromptCard');

  const stages: Stage[] = [
    {
      title: t("titleDocuments"),
      description: t("descriptionClalit"),
      buttonBackground: prompt1
    },
    {
      title: t("titleDocuments"),
      description: (
        <>
        {t("descriptionMacabi")}
        </>
      ),    buttonBackground: prompt1
    },
    {
      title: t("titleDocuments"),
      description: t("descriptionDate"),
      buttonBackground: prompt1
    },
    {
      title: t("titleDocuments"),
      description: t("descriptionBio"),
      buttonBackground: prompt1
    }
  ];

  return (
    <div className={styles.cardContainer}>
      <div className={styles.backgroundGradient} style={{ backgroundImage: `url(${stages[activeStage].buttonBackground})` }} />
      <div className={styles.titleText}>{stages[activeStage].title}</div>
      <div className={styles.descriptionText}>{stages[activeStage].description}</div>
      <Button 
        className={styles.button}
        text={t("button")} 
        icon="right-side"
        iconColorType="stroke"
        id={"let-me-see-btn"}
        onClick={() => onClick(`prompt${activeStage + 1}`, extractText(stages[activeStage].description))}
      />
      <div className={styles.dotsContainer}>
        {stages.map((_, i) => (
          <div key={i} className={`${styles.dot} ${i === activeStage ? styles.dotActive : styles.dotInactive}`} onClick={() => setActiveStage(i)} />
        ))}
      </div>
    </div>
  );
};

export default PromptCard;
