import React from "react";
import styles from "./css/ChatPrompt.module.css";
import { useTranslation } from 'react-i18next';
import { langDirection } from "@/utils/languages";

interface Prompt {
  id: string;
  textH: string;
  textE: string;
}

interface ChatPromptProps {
  prompt: Prompt;
  onPromptClick: (promptId: string, promptText: string) => void;
}

const ChatPrompt: React.FC<ChatPromptProps> = ({ prompt, onPromptClick }) => {
  const { i18n } = useTranslation('LanguageSwitcher');
  const currentLanguage = i18n.language;

  const promptText = langDirection(currentLanguage) === "rtl" ? prompt.textH : prompt.textE;

  const boldWords =
    currentLanguage === 'he'
      ? ["להתנתק", "לעבור", "שיחות", "שיחה", "המילה", "צעק"]
      : ["disconnect", "switch", "calls", "call", "word", "yelled"];

  return (
    <div
      className={styles.container}
      onClick={() => onPromptClick(prompt.id, promptText)}
    >
      <p className={styles.promptText}>
        {promptText
          .split(" ")
          .map((word, index) =>
            boldWords.includes(word.replace(/[^a-zA-Zא-ת]/g, "").toLowerCase()) ? (
              <strong key={index}>{word} </strong>
            ) : (
              word + " "
            )
          )}
      </p>
    </div>
  );
};

export default ChatPrompt;
