import React, { useState, useEffect } from "react";
import { FileCard } from "../cards/FileCard";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { useTranslation } from "react-i18next";
import { useGetFile } from "@/hooks/files/useGetFile";
import { GetDeleteFile } from "@/types";
import ImageGeneratingAnimation from "./ImageGeneratingAnimation";
import { setChatState } from "@/store/chatSlice";
import { langDirection } from "@/utils/languages";

interface SearchMessageProps {}

const SearchMessage: React.FC<SearchMessageProps> = () => {
  const { t } = useTranslation("SearchMessage");
  const dispatch = useDispatch();
  const [displayedMessage, setDisplayedMessage] = useState("");
  const [showSources, setShowSources] = useState(false);
  const [showNewSection, setShowNewSection] = useState(false);
  const [dots, setDots] = useState("");
  const [imageUrl, setImageUrl] = useState<string | null>(null); // New state for image URL
  const chat = useSelector((state: RootState) => state.chat);
  const allProjects = useSelector((state: RootState) => state.projects.projects)
  const files = chat.responseFilteredFiles;
  const [fileId, setFileId] = useState<string | null>(chat.responseFileId);
  const [fetchFile, setFetchFile] = useState(false);
  const [failed, setFailed] = useState(false);
  const message = chat.responseMessage;
  const { i18n } = useTranslation("LanguageSwitcher");
  const currentLanguage = i18n.language;
  const isRtl = langDirection(currentLanguage) === "rtl";

  const getFile = useGetFile({
    onSuccess(data) {
      setFailed(false);
      if (data.status.generated) {
        setImageUrl(data.url);
        dispatch(setChatState({ chatWaiting: false }));
      } else {
        setFetchFile((prev) => !prev);
      }
    },
    onError(error) {
      setFailed(true);
      dispatch(
        setChatState({
          chatWaiting: false,
          responseMessage:
            "We apologize, but we couldn't get an answer from the server. Please try again later.",
        })
      );
    },
  });

  useEffect(() => {
    if (chat.chatWaiting) {
      setDisplayedMessage("");
      setShowSources(false);
      setShowNewSection(false);
      const dotTimer = setInterval(() => {
        setDots((prevDots) => (prevDots.length < 3 ? prevDots + "." : ""));
      }, 500);
      return () => clearInterval(dotTimer);
    } else {
      setDots(""); // Reset dots when not waiting
      let index = 0;
      const timer = setInterval(() => {
        if (index < message.length) {
          const char = message[index];
          if (char !== undefined) {
            setDisplayedMessage((prev) => prev + char);
          }
          index++;
        } else {
          clearInterval(timer);
          setTimeout(() => {
            setShowSources(true);
            setShowNewSection(true);
          }, 500);
        }
      }, 40);

      return () => clearInterval(timer);
    }
  }, [message, chat.chatWaiting]);

  useEffect(() => {
    if (failed) {
      // If failed, clear fileId to stop further attempts
      setFileId(null);
      return;
    }

    if (!fileId) {
      if (!chat.chatWaiting) {
        setImageUrl(null);
        return;
      }
      if (!chat.responseFileId) {
        setImageUrl(null);
        return;
      } else {
        setFileId(chat.responseFileId);
      }
    }

    const checkFileStatus = setInterval(() => {
      if (failed) {
        clearInterval(checkFileStatus);
        return;
      }

      getFile
        .mutateAsync(chat.bodyFile as GetDeleteFile)
        .then((updatedFile) => {
          if (updatedFile.status.generated) {
            setImageUrl(updatedFile.url);
            clearInterval(checkFileStatus);
          }
        })
        .catch(() => {
          setFailed(true);
          clearInterval(checkFileStatus);
        });
    }, 3000);

    return () => clearInterval(checkFileStatus);
  }, [fileId, chat.responseFileId, fetchFile, failed]);

  useEffect(() => {
    if (chat.bodyFile) getFile.mutate(chat.bodyFile);
  }, [chat.bodyFile]);

  return (
    <div className="flex flex-col items-center">
      {/* Message and files container */}
      <div
        className={`w-[800px] px-4 py-4 bg-[#FCFCFC] rounded-lg ${chat.chatSearchFocus === "generate" ? "flex items-center justify-center" : ""}`}
      >
        {chat.chatSearchFocus === "generate" && chat.chatWaiting ? ( // New condition for image result
          <div className="text-center">
            <ImageGeneratingAnimation />
          </div>
        ) : imageUrl ? ( // Show image when available
          <img
            src={imageUrl}
            alt="Generated result"
            className="w-[30rem] h-[30rem] shadow-lg rounded-[8px]"
          />
        ) : (
          <div className="text-[#333333] text-sm font-normal font-['Roboto'] leading-5 mb-1">
            {chat.chatWaiting ? (
              <span>
                {t("spanhMessage")}
                {dots}
              </span>
            ) : (
              <span>
                <div
                  style={{
                    direction: /[\u0590-\u05FF]/.test(displayedMessage)
                      ? "rtl"
                      : "ltr",
                  }}
                >
                  {displayedMessage}
                </div>
              </span>
            )}
          </div>
        )}
        {files.length > 0 && showSources && (
          <div className="text-[#333333] text-sm font-semibold font-['Roboto'] leading-6">
            <p>{t("sourcesAndRelatedContent")}</p>
          </div>
        )}

        {/* Separator */}
        {files.length > 0 && (
          <div className="w-full h-[1px] bg-gray-300 my-4"></div>
        )}

        {/* {files.length > 0 && showNewSection && (
          <div className="text-[#333333] text-sm font-normal font-['Roboto'] leading-5 mb-1">
            <p>{isRtl? `מצאנו ${files.length} קבצים קשורים לחיפוש שלך.` : `We found ${files.length} related files for your search.`}</p>
          </div>
        )} */}

        {/* FileCards section in rows */}
        {showNewSection && (
          <div className="file-section-container max-h-80 overflow-y-auto">
            <div className="flex flex-wrap gap-4">
              {files.map((file, index) => {
                const project = allProjects.find(
                  (project) => project.id === file.project_id
                );
                return <FileCard
                  key={index}
                  data={file}
                  projectName={project?.name || "test project"}
                  projectColor={"blue"}
                />;
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchMessage;
