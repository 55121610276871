import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
 
import LoginPageTranslations from './locales/LoginPage.json';
import FooterTranslations from './locales/Footer.json';
import OrganizationSettingsTabTranslations from './locales/OrganizationSettingsTab.json';
import PurchasedCardTranslations from './locales/PurchasedCard.json';
import UsersPageTranslations from './locales/UsersPage.json';
import SearchBarTranslations from './locales/SearchBar.json';
import UsersTableTranslations from './locales/UsersTable.json'
import SettingPageTranslations from './locales/SettingPage.json';
import OrganizationSettingPageTranslations from './locales/OrganizationSettingPage.json';
import ProjectSettingsPageTranslations from './locales/ProjectSettingsPage.json';
import OrganizationProjectsTableTranslations from './locales/OrganizationProjectsTable.json';
import LoginCardTranslations from './locales/LoginCard.json';
import CreateNewOrgCardTranslations from './locales/CreateNewOrgCard.json';
import OrganizationProjectsTabTranslations from './locales/OrganizationProjectsTab.json';
import LoadingPageAnimationTranslations from './locales/LoadingPageAnimation.json'
import CreateNewProjectCardTranslations from './locales/CreateNewProjectCard.json'
import ProjectsPageTranslations from './locales/ProjectsPage.json'
import ForbiddenPageTranslations from './locales/ForbiddenPage.json'
import NewOrganizationTranslations from './locales/NewOrganization.json'
import NotFoundPageTranslations from './locales/NotFoundPage.json'
import UserSettingsPageTranslations from './locales/UserSettingsPage.json'
import AdminUserFormCardTranslations from './locales/AdminUserFormCard.json'
import ChatFocusTranslations from './locales/ChatFocus.json'
import FileCardTranslations from './locales/FileCard.json'
import MarketplaceButtonTranslations from './locales/MarketplaceButton.json'
import NavbarTranslations from './locales/Navbar.json'
import LanguageSwitcherTranslations from './locales/LanguageSwitcher.json'
import SideBarTranslations from './locales/SideBar.json'
import UserProjectPageTranslations from './locales/UserProjectPage.json'
import PromptCardTranslations from './locales/PromptCard.json'
import ChatInputTranslations from './locales/ChatInput.json'
import FilesTableTranslations from './locales/FilesTable.json'
import PreviewImageModalTranslations from './locales/PreviewImageModal.json'
import EmailInputTranslations from './locales/EmailInput.json'
import UserModalTranslations from './locales/UserModal.json'
import SearchResTranslations from './locales/SearchRes.json'
import UsersAttachmentsTranslations from './locales/UsersAttachments.json'
import CreateProjectPageTranslations from './locales/CreateProjectPage.json'
import SearchMessageTranslations from './locales/SearchMessage.json'
import SentimentalTranslations from './locales/Sentimental.json'
import AudioViewTranslations from './locales/AudioView.json'
import PdfViewTranslations from './locales/PdfView.json'
import ImageViewTranslations from './locales/ImageView.json'
import FileHeaderTranslations from "./locales/FileHeader.json"
import ChatInteractMessagesTranslations from "./locales/ChatInteractMessages.json"
import ChatFileTranslations from "./locales/ChatFile.json"
import ResultsViewTranslations from "./locales/ResultsView.json"

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: true,
    ns: ['LoginPage', 'Footer',
     'OrganizationSettingPage','OrganizationProjectsTable',
     'ProjectSettingsPage','CreateNewProjectCard','LoginCard',
      'CreateNewOrgCard','OrganizationProjectsTab',
      'ForbiddenPage', 'NewOrganization',
      'NotFoundPage', 'UserSettingsPage',
      'MarketplaceButton', 'NavbarTranslations','ChatFocus',
      'NotFoundPage', 'UserSettingsPage','FileCard','UsersTable','UsersPage',
      'SettingPage','OrganizationSettingsTab','PurchasedCard','ProjectsPage',
      'UserProjectPage', 'PromptCard',
      'ChatInput', 'FilesTable', 'PreviewImageModal',
      'EmailInput', 'UserModal', 'UsersAttachments',
      'CreateProjectPage', 'SearchRes', 'SearchMessage', 'AudioView', 'PdfView',
         'Sentimental'
    ],
    defaultNS: 'loginPage',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['querystring', 'localStorage', 'navigator', 'htmlTag'],
      caches: ['localStorage'],
    },
    resources: {
      en: {
        LoginPage: LoginPageTranslations.en,
        Footer: FooterTranslations.en,
        OrganizationSettingsTab: OrganizationSettingsTabTranslations.en,
        PurchasedCard: PurchasedCardTranslations.en,
        UsersPage: UsersPageTranslations.en,
        SearchBar: SearchBarTranslations.en,
        UsersTable: UsersTableTranslations.en,
        SettingPage: SettingPageTranslations.en,
        OrganizationSettingPage: OrganizationSettingPageTranslations.en,
        ProjectSettingsPage: ProjectSettingsPageTranslations.en,
        OrganizationProjectsTable: OrganizationProjectsTableTranslations.en,
        LoginCard: LoginCardTranslations.en,
        CreateNewOrgCard: CreateNewOrgCardTranslations.en,
        OrganizationProjectsTab: OrganizationProjectsTabTranslations.en,
        LoadingPageAnimation: LoadingPageAnimationTranslations.en,
        CreateNewProjectCard: CreateNewProjectCardTranslations.en,
        ProjectsPage: ProjectsPageTranslations.en,
        ForbiddenPage: ForbiddenPageTranslations.en,
        NewOrganization: NewOrganizationTranslations.en,
        NotFoundPage: NotFoundPageTranslations.en,
        UserSettingsPage: UserSettingsPageTranslations.en,
        AdminUserFormCard: AdminUserFormCardTranslations.en,
        ChatFocus: ChatFocusTranslations.en,
        FileCard: FileCardTranslations.en,
        MarketplaceButton: MarketplaceButtonTranslations.en,
        Navbar: NavbarTranslations.en,
        LanguageSwitcher: LanguageSwitcherTranslations.en,
        SideBar: SideBarTranslations.en,
        UserProjectPage: UserProjectPageTranslations.en,
        PromptCard: PromptCardTranslations.en,
        ChatInput: ChatInputTranslations.en,
        FilesTable: FilesTableTranslations.en,
        PreviewImageModal: PreviewImageModalTranslations.en,
        EmailInput: EmailInputTranslations.en,
        UserModal: UserModalTranslations.en,
        SearchRes: SearchResTranslations.en,
        UsersAttachments: UsersAttachmentsTranslations.en,
        CreateProjectPage: CreateProjectPageTranslations.en,
        SearchMessage: SearchMessageTranslations.en,
        AudioView: AudioViewTranslations.en,
        PdfView: PdfViewTranslations.en,
        ImageView: ImageViewTranslations.en,
        Sentimental: SentimentalTranslations.en,
        FileHeader: FileHeaderTranslations.en,
        ChatInteractMessages: ChatInteractMessagesTranslations.en,
        ChatFile: ChatFileTranslations.en,
        ResultView: ResultsViewTranslations.en
      },
      he: {
        LoginPage: LoginPageTranslations.he,
        Footer: FooterTranslations.he,
        OrganizationSettingsTab: OrganizationSettingsTabTranslations.he,
        PurchasedCard: PurchasedCardTranslations.he,
        UsersPage: UsersPageTranslations.he,
        SearchBar: SearchBarTranslations.he,
        UsersTable: UsersTableTranslations.he,
        SettingPage: SettingPageTranslations.he,
        OrganizationSettingPage: OrganizationSettingPageTranslations.he,
        ProjectSettingsPage: ProjectSettingsPageTranslations.he,
        OrganizationProjectsTable: OrganizationProjectsTableTranslations.he,
        LoginCard: LoginCardTranslations.he,
        CreateNewOrgCard: CreateNewOrgCardTranslations.he,
        OrganizationProjectsTab: OrganizationProjectsTabTranslations.he,
        LoadingPageAnimation: LoadingPageAnimationTranslations.he,
        CreateNewProjectCard: CreateNewProjectCardTranslations.he,
        ProjectsPage: ProjectsPageTranslations.he,
        ForbiddenPage: ForbiddenPageTranslations.he,
        NewOrganization: NewOrganizationTranslations.he,
        NotFoundPage: NotFoundPageTranslations.he,
        UserSettingsPage: UserSettingsPageTranslations.he,
        AdminUserFormCard: AdminUserFormCardTranslations.he,
        ChatFocus: ChatFocusTranslations.he,
        FileCard: FileCardTranslations.he,
        MarketplaceButton: MarketplaceButtonTranslations.he,
        Navbar: NavbarTranslations.he,
        LanguageSwitcher: LanguageSwitcherTranslations.he,
        SideBar: SideBarTranslations.he,
        UserProjectPage: UserProjectPageTranslations.he,
        PromptCard: PromptCardTranslations.he,
        ChatInput: ChatInputTranslations.he,
        FilesTable: FilesTableTranslations.he,
        PreviewImageModal: PreviewImageModalTranslations.he,
        EmailInput: EmailInputTranslations.he,
        UserModal: UserModalTranslations.he,
        SearchRes: SearchResTranslations.he,
        UsersAttachments: UsersAttachmentsTranslations.he,
        CreateProjectPage: CreateProjectPageTranslations.he,
        SearchMessage: SearchMessageTranslations.he,
        AudioView: AudioViewTranslations.he,
        PdfView: PdfViewTranslations.he,
        ImageView: ImageViewTranslations.he,
        Sentimental: SentimentalTranslations.he,
        FileHeader: FileHeaderTranslations.he,
        ChatInteractMessages: ChatInteractMessagesTranslations.he,
        ChatFile: ChatFileTranslations.he,
        ResultView: ResultsViewTranslations.he
      }
    },
  });
 
export default i18next;

