import { createSlice } from '@reduxjs/toolkit';
import secureLocalStorage from 'react-secure-storage';
import { FileData, GetDeleteFile } from '@/types';


interface FilesState {
  responseMessage: string;
  toShowPrompt: boolean;
  isChatResMode: boolean;
  chatWaiting: boolean;
  chatSearchFocus: "audio" | "doc" | "generate" | "media" | "none" ;
  chatQuery: string;
  responseFilteredFiles: FileData[];
  responseFileId: string;
  bodyFile: GetDeleteFile | null
  selectedPrompt: { id: string; text: string } | null;
  promptTagMode: boolean;
}

const initialState: FilesState = {
  responseMessage: "",
  chatWaiting: false,
  toShowPrompt: true,
  isChatResMode: false,
  chatSearchFocus: "none",
  chatQuery: "",
  responseFilteredFiles: [],
  responseFileId: "",
  bodyFile: null,
  selectedPrompt: null,
  promptTagMode: false
};

const chatSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    setChatState: (state, action) => {
      const { responseMessage, toShowPrompt, isChatResMode, chatSearchFocus, chatWaiting, chatQuery, responseFilteredFiles, responseFileId, bodyFile, promptTagMode } = action.payload;

      if (responseMessage !== undefined) {
        state.responseMessage = responseMessage;
        secureLocalStorage.setItem('responseMessage', JSON.stringify(responseMessage) || '');
      }
      if (toShowPrompt !== undefined) {
        state.toShowPrompt = toShowPrompt;
        secureLocalStorage.setItem('toShowPrompt', JSON.stringify(toShowPrompt) || '');
      }
      if (isChatResMode !== undefined) {
        state.isChatResMode = isChatResMode;
        secureLocalStorage.setItem('isChatResMode', JSON.stringify(isChatResMode) || '');
      }
      if (chatSearchFocus !== undefined) {
        state.chatSearchFocus = chatSearchFocus;
        secureLocalStorage.setItem('chatSearchFocus', JSON.stringify(chatSearchFocus) || '');
      }
      if (chatWaiting !== undefined) {
        state.chatWaiting = chatWaiting;
        secureLocalStorage.setItem('chatWaiting', JSON.stringify(chatWaiting) || '');
      }
      if (chatQuery !== undefined) {
        state.chatQuery = chatQuery;
        secureLocalStorage.setItem('chatQuery', JSON.stringify(chatQuery) || '');
      }
      if (responseFilteredFiles !== undefined) {
        state.responseFilteredFiles = responseFilteredFiles;
        secureLocalStorage.setItem('responseFilteredFiles', JSON.stringify(responseFilteredFiles) || '');
      }
      if (responseFileId !== undefined) {
        state.responseFileId = responseFileId;
        secureLocalStorage.setItem('responseFileId', JSON.stringify(responseFileId) || '');
      }
      if (bodyFile !== undefined) {
        state.bodyFile = bodyFile;
        secureLocalStorage.setItem('bodyFile', JSON.stringify(bodyFile) || '');
      }
      if (promptTagMode !== undefined) {
        state.promptTagMode = promptTagMode;
        secureLocalStorage.setItem('promptTagMode', JSON.stringify(promptTagMode) || '');
      }
    },
    setSelectedPrompt: (state, action) => {
      state.selectedPrompt = action.payload;
      secureLocalStorage.setItem('selectedPrompt', JSON.stringify(action.payload) || '');
    },
    clearSelectedPrompt: (state) => {
      state.selectedPrompt = null;
      secureLocalStorage.removeItem('selectedPrompt');
    },
    clearChat: (state) => {
      state.responseMessage = "";
      state.toShowPrompt = true;
      state.isChatResMode = false;
      state.chatSearchFocus = "none";
      state.chatWaiting = false;
      state.chatQuery = "";
      state.responseFilteredFiles = [];
      state.responseFileId = ""
      state.bodyFile = null;
      state.selectedPrompt = null;
      state.promptTagMode = false;

      secureLocalStorage.removeItem('responseMessage');
      secureLocalStorage.removeItem('toShowPrompt');
      secureLocalStorage.removeItem('isChatResMode');
      secureLocalStorage.removeItem('chatSearchFocus');
      secureLocalStorage.removeItem('chatWaiting');
      secureLocalStorage.removeItem('chatQuery');
      secureLocalStorage.removeItem('responseFilteredFiles');
      secureLocalStorage.removeItem('responseFieId');
      secureLocalStorage.removeItem('bodyFile');
      secureLocalStorage.removeItem('selectedPrompt');
      secureLocalStorage.removeItem('promptTagMode');

    },
  },
});

export const { setChatState, clearChat, setSelectedPrompt, clearSelectedPrompt } = chatSlice.actions;
export default chatSlice.reducer;
