import React, { useEffect, useRef, useState } from "react";
import styles from "./css/ChatFile.module.css";
import { useTranslation } from "react-i18next";
import { langDirection } from "@/utils/languages";
import { useChatInteracte } from "@/hooks/chat/useChatInteract";
import { FileData } from "@/types";
interface Props {
  file: FileData;
  setResults: (value: string) => void;
  setQuerys: (value: string) => void;
}

export const ChatFile: React.FC<Props> = ({ file, setResults, setQuerys }) => {
  const { t, i18n } = useTranslation("ChatFile");
  const direction = langDirection(i18n.language);
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [thinking, setThinking] = useState(false);
  const [thinkingDots, setThinkingDots] = useState(".");

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const chatInterract = useChatInteracte({
    onSuccess(data) {
      setResults(data.answer);
      setThinking(false);
    },
    onError(error) {
      setResults(t("error"));
      setThinking(false);
    },
  });

  const onSubmit = (value: string) => {
    chatInterract.mutate({
      file_id: file.id,
      org_id: file.org_id,
      project_id: file.project_id,
      query: value,
    });
    setQuerys(value);
    setThinking(true);
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (thinking) {
      interval = setInterval(() => {
        setThinkingDots((prev) => {
          if (prev.length < 3) {
            return prev + ".";
          }
          return "";
        });
      }, 300); // Change the interval as needed
    } else {
      setThinkingDots("."); // Reset dots when not thinking
    }
    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [thinking]);

  const handleSubmit = () => {
    if (inputValue.trim()) {
      onSubmit(inputValue);
      setInputValue("");
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && (e.metaKey || e.shiftKey)) {
      e.preventDefault();
      setInputValue(inputValue + "\n");
    } else if (e.key === "Enter" && !e.shiftKey && !e.metaKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [inputValue]);

  return (
    <div className={styles.inputContainer}>
      {thinking ? (
        <span className={styles.thinking}>{t('thinking')}{thinkingDots}</span>
      ) : (
        <textarea
          ref={textareaRef}
          placeholder={t("placeholder")}
          className={styles.input}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={() => {
            setIsFocused(true);
          }}
          dir={direction}
          onBlur={() => setIsFocused(false)}
          rows={1}
        />
      )}
    </div>
  );
};
