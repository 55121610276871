import React, { useState, useEffect } from "react";
import { UserDocsIcon } from "@/icons/UserDocsIcon";
import { UserAudioIcon } from "@/icons/UserAudioIcon";
import { SpakleIcon } from "@/icons/SpakleIcon";
import { UserMediaIcon } from "@/icons/userMediaIcon";
import styles from "./css/ChatFocus.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setChatState } from "@/store/chatSlice";
import { RootState } from "@/store/store";
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { langDirection } from "@/utils/languages";

interface ChatFocusProps {
  onClose: () => void;
}

const ChatFocus: React.FC<ChatFocusProps> = ({ onClose }) => {
  const { t } = useTranslation('ChatFocus');
  const dispatch = useDispatch();
  const chatSearchFocus = useSelector((state: RootState) => state.chat.chatSearchFocus);
  const orgName = useSelector((state: RootState) => state.org.userOrg.org_name);
  const [active, setActive] = useState<"audio" | "doc" | "generate" | "media" |"none">(chatSearchFocus);
  const location = useLocation();
  const decodedPathname = decodeURIComponent(location.pathname);

  const isDeshbord = (decodedPathname === `/${orgName}/dashboard`);
  const isMeida = (decodedPathname === `/${orgName}/media/files`);

  const { i18n } = useTranslation('LanguageSwitcher');
  const currentLanguage = i18n.language;
  const shouldRotate = langDirection(currentLanguage) === "rtl"
  const direction = langDirection(currentLanguage);

  useEffect(() => {
    if (!isDeshbord && !isMeida) {
      onClose();
    }
  }, [isDeshbord, onClose]);

  const handleAudioClick = () => {
    dispatch(setChatState({ chatSearchFocus: "audio", toShowPrompt: true }));
    setActive('audio')
    onClose();
  };

  const handleDocClick = () => {
    dispatch(setChatState({ chatSearchFocus: "doc", toShowPrompt: true }));
    setActive('doc')
    onClose();
  };

  const handleGenerateClick = () => {
    dispatch(setChatState({ chatSearchFocus: "generate", toShowPrompt: true }));
    setActive('generate')
    onClose();
  };

  const handleMediaClick = () => {
    dispatch(setChatState({ chatSearchFocus: "media", toShowPrompt: true }));
    setActive('media')
    onClose();
  };

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className={shouldRotate ? styles.containerH: styles.containerE} onClick={handleOutsideClick}>
      <div className={styles.header}>
        {/* Header Text */}
        <div className={styles.headerText}>
          {t("headerText")}
        </div>

        {/* Audio Option */}
        {isDeshbord && (
          <div
            className={`${styles.modal} ${active === "audio" ? styles.optionActive : ""}`}
            onClick={handleAudioClick}
          >
            <UserAudioIcon className={`${styles.icon} ${styles[direction]}`} />
            <div className={styles.modalText}>
              {t("optionTextAudio")}
            </div>
          </div>
        )}

        {/* Document Option */}
        {isDeshbord && (
          <div
            className={`${styles.modal} ${active === "doc" ? styles.optionActive : ""}`}
            onClick={handleDocClick}
          >
            <UserDocsIcon className={`${styles.icon} ${styles[direction]}`} />
            <div className={styles.modalText}>
              {t("optionTextDoc")}
            </div>
          </div>
        )}

        {/* Media Option */}
        {isDeshbord && (
          <div
            className={`${styles.modal} ${active === "media" ? styles.optionActive : ""}`}
            onClick={handleMediaClick}
          >
            <UserMediaIcon className={`${styles.icon} ${styles[direction]}`} />
            <div className={styles.modalText}>
              {t("optionTextMedia")}
            </div>
          </div>
        )}

        {/* Generate Option */}
        {(isDeshbord || isMeida) && (
          <div
            className={`${styles.modal} ${active === "generate" ? styles.optionActive : ""}`}
            onClick={handleGenerateClick}
          >
            <SpakleIcon className={`${styles.icon} ${styles[direction]}`} />
            <div className={styles.modalText}>
              {t("optionTextGenerate")}
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

export default ChatFocus;
