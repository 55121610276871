import styles from "./css/UploadPictureCard.module.css";
import { useTranslation } from 'react-i18next';


export const PreviewImageModal: React.FC<{ imageUrl: string; onClose: () => void }> = ({
    imageUrl,
    onClose,
  }) => {
    const { t } = useTranslation('FileCard');

    return (
      <div className={styles.modal} onClick={onClose}>
        <div className="bg-white p-4">
          <img src={imageUrl} alt={t("Uploaded")} className="max-w-[500px] max-h-[500px]" />
        </div>
      </div>
    );
  };