import React, { useReducer, useRef, useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import Cropper from 'react-easy-crop';
import { Point, Area } from 'react-easy-crop/types';
import { Progress } from "@/components/addons/Progress";
import EyeImage from "@/assets/eye.png";
import DeleteImage from "@/assets/trash.png";
import UserImage from "@/assets/user.png";
import OrgImage from "@/assets/org.png";
import PictureImage from "@/assets/picture.svg";
import { orgColorOptions } from "@/types";
import styles from "./css/UploadPictureCard.module.css";
import { setToast } from "@/store/systemSlice";
import { PreviewImageModal } from "./PreviewImageModal";
import { useTranslation } from "react-i18next";
import { langDirection } from "@/utils/languages";

const initialState = (existingImageUrl: string | undefined) => ({
  status: existingImageUrl ? "normal" : "not_uploaded",
  imageUrl: existingImageUrl || "",
  error: null,
});

function reducer(state: any, action: any) {
  switch (action.type) {
    case "mouse_enter":
      if (state.status === "normal") {
        return { ...state, status: "hovered" };
      }
      return state;
    case "mouse_leave":
      if (state.status === "hovered") {
        return { ...state, status: "normal" };
      }
      return state;
    case "click_upload":
      return { ...state, status: "uploading" };
    case "upload_success":
      return {
        ...state,
        status: "normal",
        imageUrl: action.payload,
        error: null,
      };
    case "upload_error":
      console.error("Upload failed:", action.payload);
      return { ...state, status: "error", error: action.payload };
    case "retry_upload":
      return { ...state, status: "not_uploaded", error: null };
    case "delete_image":
      return { ...state, status: "not_uploaded", imageUrl: "", error: null };
    default:
      console.error("Unknown action type:", action.type);
      return state;
  }
}

export const UploadPictureCard: React.FC<{
  isOrgUpload: boolean;
  orgColor?: orgColorOptions;
  file: File | null;
  existingImageUrl?: string;
  setFile: (file: File | null) => void;
  setAction: (action: string) => void;
}> = ({
  isOrgUpload,
  setFile,
  orgColor = "blue",
  existingImageUrl,
  setAction,
}) => {
  const { i18n } = useTranslation("LanguageSwitcher");
  const currentLanguage = i18n.language;
  const direction = langDirection(currentLanguage);
  const [state, dispatch] = useReducer(reducer, initialState(existingImageUrl));
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const usedispatch = useDispatch();
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [isCropping, setIsCropping] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  useEffect(() => {
    if (state.status === "error") {
      const timer = setTimeout(() => {
        dispatch({ type: "retry_upload" });
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [state.status]);

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = event.target.files?.[0];
    if (newFile) {
      const acceptedFormats = ["image/jpeg", "image/png", "image/gif"];
      const maxFileSize = 5 * 1024 * 1024; // 5MB

      if (!acceptedFormats.includes(newFile.type)) {
        usedispatch(
          setToast({
            toastTitle: "Upload Error",
            toastMessage: `Invalid file format: ${newFile.type}`,
            toastType: "error",
          })
        );
        return;
      }

      if (newFile.size > maxFileSize) {
        usedispatch(
          setToast({
            toastTitle: "Upload Error",
            toastMessage: `File size exceeds limit: ${newFile.size}`,
            toastType: "error",
          })
        );
        return;
      }

      const imageUrl = URL.createObjectURL(newFile);
      setIsCropping(true);
      dispatch({ type: "upload_success", payload: imageUrl });
      setAction("replace");
      setFile(newFile);
    }
  };

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const getCroppedImg = (imageSrc: string, pixelCrop: Area) => {
    const image = new Image();
    image.src = imageSrc;
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');

    if (ctx) {
      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      );
    }

    return new Promise<string>((resolve) => {
      canvas.toBlob((blob) => {
        if (blob) {
          resolve(URL.createObjectURL(blob));
        }
      }, 'image/jpeg');
    });
  };

  const handleCropSave = useCallback(async () => {
    if (croppedAreaPixels) {
      try {
        const croppedImageUrl = await getCroppedImg(state.imageUrl, croppedAreaPixels);
        dispatch({ type: "upload_success", payload: croppedImageUrl });
        setIsCropping(false);
      } catch (e) {
        console.error(e);
      }
    }
  }, [croppedAreaPixels, state.imageUrl]);

  const handleDeleteImage = () => {
    dispatch({ type: "delete_image" });
    setFile(null);
    setAction("delete");
  };

  return (
    <div 
      className={`relative w-[242px] rounded-lg h-[242px] ${styles[orgColor]} ${state.status === "error" ? "border-[#1099fe]" : ""} border border-solid`}
      onMouseEnter={() => dispatch({ type: "mouse_enter" })}
      onMouseLeave={() => dispatch({ type: "mouse_leave" })}
    >
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        onClick={(e) => (e.currentTarget.value = "")}
      />

      {isCropping ? (
        <div className="absolute inset-0">
          <Cropper
            image={state.imageUrl}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
          <button 
            className="absolute bottom-2 right-2 bg-blue-500 text-white px-2 py-1 rounded shadow-lg bg-skyBlue-500"
            onClick={handleCropSave}
          >
            Save Crop
          </button>
        </div>
      ) : (
        <div
          className={`relative ${
            state.status === "hovered" ? `w-[225px] h-[225px] ${direction === "rtl"? "left-[1.3rem] top-2": "left-2 top-2"}` : "w-[210px] h-[210px] left-4 top-4"
          }`}
          onClick={state.status === "not_uploaded" ? handleUploadClick : undefined}
        >
          {(state.status === "normal" || state.status === "hovered") &&
            state.imageUrl && (
              <div className={`relative w-full h-full ${direction === "rtl"? "mr-[1.8rem]": ""}`}>
                <img
                  className={`absolute w-full h-full object-cover`}
                  alt="Uploaded"
                  src={state.imageUrl}
                />
                {state.status === "hovered" && (
                  <div className={`absolute w-full h-full top-0 left-0 bg-[#00000080] flex items-center justify-center `}>
                    <div className="inline-flex items-center gap-2">
                      <img
                        src={EyeImage}
                        alt="Eye"
                        className="!w-4 !h-4 cursor-pointer"
                        onClick={() => setModalOpen(true)}
                      />
                      <img
                        src={DeleteImage}
                        alt="Delete"
                        className="!w-4 !h-4 cursor-pointer"
                        onClick={handleDeleteImage}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}

          {state.status === "not_uploaded" && !state.imageUrl && (
            <div className={`flex items-center justify-center w-full h-full bg-[#1099fe] cursor-pointer ${direction === "rtl"? "mr-[1.8rem]": ""}`}>
              <img
                src={isOrgUpload ? OrgImage : UserImage}
                alt="User"
                className="!w-20 !h-20"
              />
            </div>
          )}

          {state.status === "uploading" && (
            <div className={`flex flex-col items-center justify-center w-full h-full ${direction === "rtl"? "mr-[1.8rem]": ""}`}>
              <div className="relative w-fit [font-family:'Roboto-Regular',Helvetica] font-normal text-[#000000d9] text-sm text-center tracking-[0] leading-[22px] whitespace-nowrap mb-2">
                Uploading
              </div>
              <Progress />
            </div>
          )}

          {state.status === "error" && (
            <div className={`flex flex-col items-center justify-center w-full h-full ${direction === "rtl"? "mr-[1.8rem]": ""}`}>
              <img
                src={PictureImage}
                alt="Picture"
                className="!w-[30px] !h-[30px] cursor-pointer mb-2"
                onClick={() => dispatch({ type: "retry_upload" })}
              />
              <div className="relative w-[88px] [font-family:'Roboto-Regular',Helvetica] font-normal text-[#1099fe] text-sm text-center tracking-[0] leading-[22px]">
                Upload Failed
              </div>
            </div>
          )}
        </div>
      )}

      {isModalOpen && (
        <PreviewImageModal
          imageUrl={state.imageUrl}
          onClose={() => setModalOpen(false)}
        />
      )}
    </div>
  );
};