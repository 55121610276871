import { PostRequest, PostRequestFormData } from "./requests";
import { GetDeleteFile, GetDeleteAllFiles, UploadFile, GenerateFile } from "@/types";

export const getFile = async (data: GetDeleteFile) => {
    try {
        return await PostRequest("/api/files/get", data);
    } catch (error) {
        console.log('Error in get file:', error);
        throw error;
    }
}

export const deleteFile = async (data: GetDeleteFile) => {
    try {
        return await PostRequest("/api/files/delete", data);
    } catch (error) {
        console.log('Error in delete file:', error);
        throw error;
    }
}

export const getAllFiles = async (data: GetDeleteAllFiles) => {
    try {
        return await PostRequest("/api/files/get_all", data);
    } catch (error) {
        console.log('Error in get all files:', error);
        throw error;
    }
}

export const getAllMediaFiles = async (data: GetDeleteAllFiles) => {
    try {
        return await PostRequest("/api/files/get_all_media", data);
    } catch (error) {
        console.log('Error in get all media files:', error);
        throw error;
    }
}


export const deleteAllFiles = async (data: GetDeleteAllFiles) => {
    try {
        return await PostRequest("/api/files/delete_all", data);
    } catch (error) {
        console.log('Error in delete all files:', error);
        throw error;
    }
}

export const uploadFile = async (data: UploadFile) => {
    try {
        return await PostRequestFormData("/api/files/create", data);
    } catch (error) {
        console.log('Error in upload file:', error);
        throw error;
    }
}

export const generateImage = async (data: GenerateFile) => {
    try {
        return await PostRequest("/api/files/create_imagegen", data);
    } catch (error) {
        console.log('Error in generate image:', error);
        throw error;
    }
}
